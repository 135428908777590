/** @standard-brand[step:"brand-information"] */

import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { CatalogBrandCodes } from "src/app/domains/catalog.definitions";
import { ListItem } from "src/shared/common-types";
import { Country } from "src/shared/countries.definitions";
import { exhaustive } from "src/shared/utils";
import { AutogenerateTupleFromUnion } from "src/shared/utils.types";
import { SafeParseReturnType, z } from "zod";

export type BrandGroup = "PCD" | "OV" | "FCA";

export enum Brand {
  AP = "AP",
  AC = "AC",
  DS = "DS",
  OV = "OV",
  FT = "FT",
  AR = "AR",
  JE = "JE",
}

/* eslint-disable id-denylist */
// eslint-disable-next-line complexity
export function tcoBrandToCatalogBrand(
  brand: Brand,
  country: Country,
): SafeParseReturnType<CatalogBrandCodes, CatalogBrandCodes> {
  switch (brand) {
    case Brand.AP:
      return { success: true, data: "PE" };
    case Brand.AC:
      return { success: true, data: "CI" };
    case Brand.DS:
      return { success: true, data: "DS" };
    case Brand.OV:
      if (country === "GB") return { success: true, data: "VA" };
      else return { success: true, data: "OP" };
    case Brand.AR:
      return { success: true, data: "AL" };
    case Brand.FT:
      return { success: true, data: "FI" };
    case Brand.JE:
      return { success: true, data: brand };
    default:
      exhaustive(brand);
  }
}
/* eslint-enable id-denylist */

export function resolveBrand(value: string): SafeParseReturnType<Brand, Brand> {
  const brandEnum = z.nativeEnum(Brand);
  return brandEnum.safeParse(value);
}

export enum BrandType {
  pcd = "pcd",
  ov = "ov",
  fca = "fca",
  all = "all",
}

// @standard-brand[step:"brand-information"]
export type BrandDisplay =
  | "Peugeot"
  | "Citroën"
  | "DS"
  | "Opel"
  | "Alfa Romeo"
  | "Fiat"
  | "Jeep"
  | "Vauxhall";
const BrandDisplayList: AutogenerateTupleFromUnion<BrandDisplay> = [
  "Peugeot",
  "Citroën",
  "DS",
  "Opel",
  "Alfa Romeo",
  "Fiat",
  "Jeep",
  "Vauxhall",
];
marker(BrandDisplayList);

// eslint-disable-next-line complexity
export function brandGroupFromBrand(brand: Brand): BrandGroup {
  switch (brand) {
    case Brand.AC:
    case Brand.AP:
    case Brand.DS:
      return "PCD";
    case Brand.OV:
      return "OV";
    case Brand.AR:
    case Brand.JE:
    case Brand.FT:
      return "FCA";
    default:
      exhaustive(brand);
  }
}

// First brand in the list is the default brand

// @standard-brand[step:"brand-information"]
export const pcdBrandsList: ListItem<Brand, BrandDisplay>[] = [
  {
    value: Brand.AP,
    valueDisplay: "Peugeot",
  },
  {
    value: Brand.AC,
    valueDisplay: "Citroën",
  },
  {
    value: Brand.DS,
    valueDisplay: "DS",
  },
];
// @standard-brand[step:"brand-information"]
export const ovBrandsList: ListItem<Brand, BrandDisplay>[] = [
  {
    value: Brand.OV,
    valueDisplay: "Opel",
  },
];
// @standard-brand[step:"brand-information"]
export const fcaBrandsList: ListItem<Brand, BrandDisplay>[] = [
  {
    value: Brand.FT,
    valueDisplay: "Fiat",
  },
  {
    value: Brand.AR,
    valueDisplay: "Alfa Romeo",
  },
  {
    value: Brand.JE,
    valueDisplay: "Jeep",
  },
];

export const BrandsList: ListItem<Brand, BrandDisplay>[] = pcdBrandsList
  .concat(ovBrandsList)
  .concat(fcaBrandsList);

function brandsListFromBrandType(
  brandType: BrandType,
): ListItem<Brand, BrandDisplay>[] {
  switch (brandType) {
    case BrandType.pcd:
      return pcdBrandsList;
    case BrandType.ov:
      return ovBrandsList;
    case BrandType.fca:
      return fcaBrandsList;
    case BrandType.all:
      return BrandsList;
    default:
      exhaustive(brandType);
  }
}

export function brandsListFromBrandTypes(
  brandTypes: BrandType[],
): ListItem<Brand, BrandDisplay>[] {
  return brandTypes.flatMap(brandsListFromBrandType);
}

export const BrandsFormatting: Record<Brand, BrandDisplay> = Object.fromEntries(
  BrandsList.map((brand) => [brand.value, brand.valueDisplay]),
) as Record<Brand, BrandDisplay>;
export const BrandsValuesList: Brand[] = BrandsList.map((_) => _.value);
