import { Brand } from "src/shared/brands.definitions";
import { Country, Language } from "src/shared/countries.definitions";
import { invertMap } from "src/shared/utils";
import { RemapUnion } from "src/shared/utils.types";
import { z } from "zod";

export const UserInfoUrl = "/user_info";

// @standard-add-country[option='ldap-wrong-country-code']
const COUNTRY_TO_LDAP_MAPPER = {
  GB: "EN",
} as const;
export const LDAP_TO_COUNTRY_MAPPER = invertMap(COUNTRY_TO_LDAP_MAPPER);

export type LanguageCountryCodeFromLdap = RemapUnion<
  Country,
  typeof COUNTRY_TO_LDAP_MAPPER
>;

const LdapCountryWithOneLanguageType = "LdapCountryWithOneLanguage";
const LdapCountryWithMultipleLanguagesType = "LdapCountryWithMultipleLanguages";

interface LdapCountryWithDefaultLanguage {
  default: Language;
}
type LdapCountryWithOneLanguage = {
  _type: typeof LdapCountryWithOneLanguageType;
} & LdapCountryWithDefaultLanguage;
type LdapCountryWithMultipleLanguages = {
  _type: typeof LdapCountryWithMultipleLanguagesType;
} & LdapCountryWithDefaultLanguage &
  Partial<Record<LanguageCountryCodeFromLdap, Language>>;

// @standard-add-country[description='Could be resolved if I understand how the LDAP works']
export const CountryToDefaultLanguageMap: Record<
  Country,
  LdapCountryWithOneLanguage | LdapCountryWithMultipleLanguages
> = {
  FR: { _type: LdapCountryWithOneLanguageType, default: "fr-FR" },
  ES: { _type: LdapCountryWithOneLanguageType, default: "es-ES" },
  GB: { _type: LdapCountryWithOneLanguageType, default: "en-GB" },
  BE: {
    _type: LdapCountryWithMultipleLanguagesType,
    FR: "fr-BE",
    NL: "nl-BE",
    default: "fr-BE",
  },
  DE: { _type: LdapCountryWithOneLanguageType, default: "de-DE" },
  AT: { _type: LdapCountryWithOneLanguageType, default: "de-AT" },
  IT: { _type: LdapCountryWithOneLanguageType, default: "it-IT" },
  NL: { _type: LdapCountryWithOneLanguageType, default: "nl-NL" },
  PL: { _type: LdapCountryWithOneLanguageType, default: "pl-PL" },
  TR: { _type: LdapCountryWithOneLanguageType, default: "tr-TR" },
  PT: { _type: LdapCountryWithOneLanguageType, default: "pt-PT" },
};

export const UserInfoParserSAML = z.object({
  uid: z.string(),
  givenname: z.string(),
  sn: z.union([z.string(), z.undefined()]),
  brand: z.union([z.string(), z.null(), z.undefined()]),
  preferredlanguage: z.union([z.string(), z.null(), z.undefined()]),
  pays: z.union([z.string(), z.undefined()]),
  email: z.union([z.string(), z.undefined()]),
  mail: z.union([z.string(), z.undefined()]),
  telephonenumber: z.union([z.array(z.string()), z.string(), z.undefined()]),
  groups: z.union([z.array(z.string()), z.string(), z.undefined()]),
  rrdi7: z.union([z.string(), z.undefined()]),
});
export type UserInfoSAML = z.infer<typeof UserInfoParserSAML>;

export const UserInfoParserOIDC = z.object({
  username: z.string(),
  firstname: z.string(),
  lastname: z.union([z.string(), z.undefined()]),
  brand: z.union([z.string(), z.null(), z.undefined()]),
  preferredlanguage: z.union([z.string(), z.null(), z.undefined()]),
  country: z.union([z.string(), z.undefined()]),
  email: z.union([z.string(), z.undefined()]),
  phone: z.union([z.array(z.string()), z.string(), z.undefined()]),
  roles: z.union([z.array(z.string()), z.string(), z.undefined()]),
  rrdi: z.union([z.string(), z.undefined()]),
});
export type UserInfoOIDC = z.infer<typeof UserInfoParserOIDC>;

export function generateMockUserInfo({
  brand,
  languageCountry,
  country,
}: {
  brand: Brand;
  languageCountry: Country | "technical";
  country: Country;
}): UserInfoSAML {
  return {
    brand,
    givenname: "Dummy",
    sn: "User",
    groups: ["ESA.USER.ALL"],
    email: "dummy@stellantis.com",
    pays: country,
    preferredlanguage: languageCountry, // Mocks the LDAP response which returns a country code
    rrdi7: "dummy",
    telephonenumber: ["+33422521010"],
    uid: "dummy",
  };
}
