import { z } from "zod";

const localStorageSchema = z.object({
  debugLogs: z.boolean().default(false),
  routerLogs: z.boolean().default(false),
  useHrefRedirectUri: z.boolean().default(false),
});

const runtimeEnvNotParsed = localStorage.getItem("tco.runtimeEnv");
const localEnv = localStorageSchema.parse(
  JSON.parse(runtimeEnvNotParsed ?? "{}"),
);
// eslint-disable-next-line no-console
console.log("Loaded configuration", localEnv);
localStorage.setItem("tco.runtimeEnv", JSON.stringify(localEnv));

export default localEnv;
