import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { retryImport } from "src/app/utils";
import { BrandGroup } from "src/shared/brands.definitions";
import { Language, TechnicalLanguage } from "src/shared/countries.definitions";

type TranslationLanguageKey = `${Language}_${BrandGroup}` | TechnicalLanguage;

// @standard-add-country @standard-brand
const translationMapping: Record<
  TranslationLanguageKey,
  () => Promise<Record<string, unknown>>
> = {
  "de-AT_OV": () => retryImport(() => import("src/assets/i18n/de-AT_OV.json")),
  "de-AT_PCD": () =>
    retryImport(() => import("src/assets/i18n/de-AT_PCDFCA.json")),
  "de-AT_FCA": () =>
    retryImport(() => import("src/assets/i18n/de-AT_PCDFCA.json")),
  "de-DE_OV": () => retryImport(() => import("src/assets/i18n/de-DE_OV.json")),
  "de-DE_PCD": () =>
    retryImport(() => import("src/assets/i18n/de-DE_PCDFCA.json")),
  "de-DE_FCA": () =>
    retryImport(() => import("src/assets/i18n/de-DE_PCDFCA.json")),
  "en-GB_OV": () => retryImport(() => import("src/assets/i18n/en-GB_OV.json")),
  "en-GB_PCD": () =>
    retryImport(() => import("src/assets/i18n/en-GB_PCDFCA.json")),
  "en-GB_FCA": () =>
    retryImport(() => import("src/assets/i18n/en-GB_PCDFCA.json")),
  "es-ES_OV": () => retryImport(() => import("src/assets/i18n/es-ES_OV.json")),
  "es-ES_PCD": () =>
    retryImport(() => import("src/assets/i18n/es-ES_PCDFCA.json")),
  "es-ES_FCA": () =>
    retryImport(() => import("src/assets/i18n/es-ES_PCDFCA.json")),
  "fr-BE_OV": () => retryImport(() => import("src/assets/i18n/fr-BE_OV.json")),
  "fr-BE_PCD": () =>
    retryImport(() => import("src/assets/i18n/fr-BE_PCDFCA.json")),
  "fr-BE_FCA": () =>
    retryImport(() => import("src/assets/i18n/fr-BE_PCDFCA.json")),
  "fr-FR_OV": () => retryImport(() => import("src/assets/i18n/fr-FR_OV.json")),
  "fr-FR_PCD": () =>
    retryImport(() => import("src/assets/i18n/fr-FR_PCDFCA.json")),
  "fr-FR_FCA": () =>
    retryImport(() => import("src/assets/i18n/fr-FR_PCDFCA.json")),
  "it-IT_OV": () => retryImport(() => import("src/assets/i18n/it-IT_OV.json")),
  "it-IT_PCD": () =>
    retryImport(() => import("src/assets/i18n/it-IT_PCDFCA.json")),
  "it-IT_FCA": () =>
    retryImport(() => import("src/assets/i18n/it-IT_PCDFCA.json")),
  "nl-BE_OV": () => retryImport(() => import("src/assets/i18n/nl-BE_OV.json")),
  "nl-BE_PCD": () =>
    retryImport(() => import("src/assets/i18n/nl-BE_PCDFCA.json")),
  "nl-BE_FCA": () =>
    retryImport(() => import("src/assets/i18n/nl-BE_PCDFCA.json")),
  "nl-NL_OV": () => retryImport(() => import("src/assets/i18n/nl-NL_OV.json")),
  "nl-NL_PCD": () =>
    retryImport(() => import("src/assets/i18n/nl-NL_PCDFCA.json")),
  "nl-NL_FCA": () =>
    retryImport(() => import("src/assets/i18n/nl-NL_PCDFCA.json")),
  "pl-PL_OV": () => retryImport(() => import("src/assets/i18n/pl-PL_OV.json")),
  "pl-PL_PCD": () =>
    retryImport(() => import("src/assets/i18n/pl-PL_PCDFCA.json")),
  "pl-PL_FCA": () =>
    retryImport(() => import("src/assets/i18n/pl-PL_PCDFCA.json")),
  "pt-PT_OV": () => retryImport(() => import("src/assets/i18n/pt-PT_OV.json")),
  "pt-PT_PCD": () =>
    retryImport(() => import("src/assets/i18n/pt-PT_PCDFCA.json")),
  "pt-PT_FCA": () =>
    retryImport(() => import("src/assets/i18n/pt-PT_PCDFCA.json")),
  "technical": () =>
    retryImport(() => import("src/assets/i18n/technical.json")),
  "tr-TR_OV": () => retryImport(() => import("src/assets/i18n/tr-TR_OV.json")),
  "tr-TR_PCD": () =>
    retryImport(() => import("src/assets/i18n/tr-TR_PCDFCA.json")),
  "tr-TR_FCA": () =>
    retryImport(() => import("src/assets/i18n/tr-TR_PCDFCA.json")),
};

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: TranslationLanguageKey): Observable<unknown> {
    return from(
      (translationMapping[lang] ?? translationMapping["fr-FR_PCD"])(),
    );
  }
}
