import { ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { captureMessage } from "@sentry/angular-ivy";
import { cloneDeep } from "lodash-es";
import { Language, TechnicalLanguage } from "src/shared/countries.definitions";
import { ONE_THOUSAND_MS } from "src/shared/magic-numbers";
import {
  assert,
  enforceBoolean,
  enforceNumber,
  enforceString,
  getObjectKeys,
  isNullish,
  readAttributeFromUnknown,
  throwIfNullish,
  unknownToString,
} from "src/shared/utils";

type KeyValueSearched = Record<string, number>;

function _checkValuesInObjectExistAndAreNumbers<
  T extends Record<string, unknown>,
>(_object: T, keySearchedList: string[]): void {
  keySearchedList.forEach((keySearched) => {
    if (
      _object[keySearched] === undefined ||
      _object[keySearched] === null ||
      typeof _object[keySearched] !== "number"
    ) {
      throw new Error(
        `Key ${keySearched} has one of the following issues relating to ${JSON.stringify(
          _object,
        )}: does not exist, is null or not a number`,
      );
    }
  });
}

function _getKeyFromKeyValueSearched(keyValueSearched: KeyValueSearched) {
  const keys = Object.keys(keyValueSearched);
  if (keys.length !== 1) {
    throw new Error(
      `Malformed keyValueSearched object ${JSON.stringify(
        keyValueSearched,
      )}. Expected one key and value`,
    );
  }

  return keys[0];
}

function _getClosestObjectsList<T extends Record<string, unknown>>(
  arrayOfObjects: T[],
  keyValueSearched: KeyValueSearched,
): T[] {
  const targetKey = _getKeyFromKeyValueSearched(keyValueSearched);
  if (!(targetKey in keyValueSearched)) {
    throw new Error(
      `Key ${targetKey} does not exist in ${JSON.stringify(keyValueSearched)}`,
    );
  }

  const targetValue = keyValueSearched[targetKey];
  const closestObjectsWithDistance: [T, number][] = arrayOfObjects
    .filter((object) => {
      const objectValue = object[targetKey];
      if (typeof objectValue !== "number") {
        throw new Error(
          `Object ${JSON.stringify(
            object,
          )} has a non-number value for key ${targetKey}: ${unknownToString(
            objectValue,
          )}`,
        );
      }
      return objectValue <= targetValue;
    })
    .map((object) => {
      _checkValuesInObjectExistAndAreNumbers(object, [targetKey]);
      const objectValue = object[targetKey];
      if (typeof objectValue !== "number") {
        throw new Error(
          `Object ${JSON.stringify(object)} has a non-number value`,
        );
      }
      const distance = Math.abs(objectValue - targetValue);
      return [object, distance];
    });
  const minDistance = Math.min(
    ...closestObjectsWithDistance.map(([, distance]) => distance),
  );
  return closestObjectsWithDistance
    .filter(([, distance]) => distance === minDistance)
    .map(([object]) => object);
}

function isArray(
  keyValueSearched: Record<string, number> | Record<string, number>[],
) {
  return keyValueSearched.hasOwnProperty("length");
}

export function getClosestLeftValue<T extends Record<string, unknown>>(
  arrayOfObjects: T[],
  keyValueSearched: KeyValueSearched | KeyValueSearched[],
): T | undefined {
  if (arrayOfObjects.length === 0) return undefined;

  const keyValueSearchedAsList: KeyValueSearched[] = isArray(keyValueSearched)
    ? (keyValueSearched as KeyValueSearched[])
    : [keyValueSearched as KeyValueSearched];

  const closestObjects = keyValueSearchedAsList.reduce(
    (filteredArrayOfObjects, keyValueSearched) =>
      _getClosestObjectsList(filteredArrayOfObjects, keyValueSearched),
    arrayOfObjects,
  );

  const closestObject =
    closestObjects.length > 0 ? closestObjects.at(-1) : arrayOfObjects[0];

  return cloneDeep(closestObject);
}

function getSeparators(
  language: Language,
): Record<"decimalSep" | "thousandsSep", string> {
  const numberWithAllSeparators = 1000000.1;
  const formattedParts = Intl.NumberFormat(language).formatToParts(
    numberWithAllSeparators,
  );

  return {
    decimalSep: throwIfNullish(
      formattedParts.find((part) => part.type === "decimal")?.value,
      { variableName: "decimalSep" },
    ),
    thousandsSep: throwIfNullish(
      formattedParts.find((part) => part.type === "group")?.value,
      { variableName: "decimalSep" },
    ),
  };
}

export function parseFormattedFloat(
  numString: string,
  language: Language | TechnicalLanguage,
): number {
  if (language === "technical") return parseFloat(numString);

  const { decimalSep, thousandsSep } = getSeparators(language);

  const numSanitized = numString.replace(/^[^0-9.,-]*/gu, "");
  const numStringWithENThousands = numSanitized.split(thousandsSep).join("");
  const numStringWithENDecimal = numStringWithENThousands
    .split(decimalSep)
    .join(".");

  return parseFloat(numStringWithENDecimal);
}

export function translateOrReturnStringError(
  translate: TranslateService,
  value: string,
  parameters?: Record<string, string>,
): string {
  const translation = enforceString(translate.instant(value, parameters));
  if (translation === "") return `NO TRANSLATION FOR _${value}_`;

  return translation;
}

type QueryParamsSchema<K extends string> = Record<
  K,
  {
    path: string;
    type: "string" | "number" | "boolean";
  }
>;

type ParsedQueryParam<
  K extends string,
  Schema extends QueryParamsSchema<K>,
> = Partial<{
  [key in K]: Schema[key]["type"] extends "number"
    ? number
    : Schema[key]["type"] extends "boolean"
      ? boolean
      : string;
}>;

export function parseQueryParams<
  K extends string,
  S extends QueryParamsSchema<K>,
>(schema: S, queryParams: ParamMap): ParsedQueryParam<K, S> {
  return getObjectKeys(schema).reduce<ParsedQueryParam<K, S>>(
    (parsedQueryParams, key) => {
      const { path, type } = schema[key];
      const valueFromQueryParams = queryParams.get(path);
      if (isNullish(valueFromQueryParams)) return parsedQueryParams;

      const parsedValue =
        type === "number"
          ? enforceNumber(JSON.parse(valueFromQueryParams))
          : type === "boolean"
            ? enforceBoolean(JSON.parse(valueFromQueryParams))
            : enforceString(valueFromQueryParams);

      return {
        ...parsedQueryParams,
        [key]: parsedValue,
      };
    },
    {},
  );
}

export function exponentialBackoff(
  maxRetries: number,
  minTimeMs: number,
  maxTimeMs: number,
  randomFraction = 5,
) {
  const a = (Math.log2(maxTimeMs) - Math.log2(minTimeMs)) / (maxRetries - 1);
  const b = Math.log2(minTimeMs) - a;

  assert(a > 0, "exponential factor growth must be positive");

  return (retryAttempt: number): number => {
    // We keep randomMilliseconds between -maxRandomNumberMilliseconds and maxRandomNumberMilliseconds
    const exponentialBackoffTime = 2 ** (a * retryAttempt + b);

    const maxRandomNumberMilliseconds = exponentialBackoffTime / randomFraction;

    // We give or take `randomFraction` of the exponential backoff time
    const randomMilliseconds =
      Math.random() * maxRandomNumberMilliseconds * 2 -
      maxRandomNumberMilliseconds;

    return exponentialBackoffTime + randomMilliseconds;
  };
}

async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function retryPromise<T>(
  mainPromise: (retries: number) => Promise<T>,
  maxRetries = 5,
  retryDelay = exponentialBackoff(
    maxRetries,
    ONE_THOUSAND_MS / 10,
    10 * ONE_THOUSAND_MS,
  ),
  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
  onError = (error: unknown, retries: number) => {
    captureMessage(
      `Retrying promise due to error: ${unknownToString(
        error,
      )}. (Retries: ${retries} of ${maxRetries})})`,
      "info",
    );
  },
  fallbackPromise: (error: unknown, retries: number) => Promise<T> = (
    _,
    retries,
  ) => mainPromise(retries),
): Promise<T> {
  try {
    return await mainPromise(0);
  } catch (error) {
    onError(error, 0);
    // eslint-disable-next-line no-plusplus
    for (let retries = 1; retries <= maxRetries; retries++) {
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      await sleep(retryDelay(retries));

      try {
        // eslint-disable-next-line no-await-in-loop
        return await fallbackPromise(error, retries);
      } catch (retriedError) {
        onError(retriedError, retries);
      }
    }
    throw error;
  }
}

export function retryImport<T>(
  mainImportPromise: () => Promise<T>,
  maxRetries = 5,
  retryDelay = exponentialBackoff(
    maxRetries,
    ONE_THOUSAND_MS / 10,
    10 * ONE_THOUSAND_MS,
  ),
  onError = (error: unknown, retries: number) => {
    captureMessage(
      `Retrying import due to error: ${unknownToString(
        error,
      )}. (Retries: ${retries} of ${maxRetries})})`,
      "info",
    );
  },
): Promise<T> {
  const fallbackPromise = async (error: unknown): Promise<T> => {
    const errorMessage = readAttributeFromUnknown(
      error,
      "message",
      "string",
      "",
    );
    if (errorMessage === undefined) throw error;

    /*
     * This assumes that the error has the format `(error: http://localhost:4200/837.js)`
     */
    const regex = /\(error: (?<moduleUrl>https?.*)\)/u;
    const result = errorMessage.match(regex);
    if (result === null) throw error;
    const moduleUrl = result.groups?.moduleUrl;
    if (moduleUrl === undefined) throw error;

    const url = new URL(moduleUrl.trim());
    /*
     * Add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
     * See also https://github.com/whatwg/html/issues/6768
     */
    url.searchParams.set("t", Date.now().toString());

    return import(url.href) as Promise<T>;
  };

  return retryPromise<T>(
    mainImportPromise,
    maxRetries,
    retryDelay,
    onError,
    fallbackPromise,
  );
}
