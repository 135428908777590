/* eslint-disable max-lines */
// @standard-brand

import { Injectable } from "@angular/core";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { ReplaySubject } from "rxjs";
import { UserParamsService } from "src/app/services/user-params.service";
import { Brand, BrandDisplay } from "src/shared/brands.definitions";
import { exhaustive } from "src/shared/utils";
import {
  AutogenerateTupleFromUnion,
  CleanAccents,
  RemoveSpaces,
} from "src/shared/utils.types";

/*
 * TODO: Refacto this in a single list
 * const keys = ['name', 'age'] as const;
 * type Keys = typeof keys[number];
 * Blocker: `marker` is not very smart and cannot resolve a string at runtime.
 */

type smallBrandName = CleanAccents<RemoveSpaces<Lowercase<BrandDisplay>>>;

type LogoFinanceImageToken = `${smallBrandName}LogoFinanceImage`;
const logoFinanceImageTokens: AutogenerateTupleFromUnion<LogoFinanceImageToken> =
  [
    "peugeotLogoFinanceImage",
    "dsLogoFinanceImage",
    "opelLogoFinanceImage",
    "fiatLogoFinanceImage",
    "jeepLogoFinanceImage",
    "vauxhallLogoFinanceImage",
    "alfaromeoLogoFinanceImage",
    "citroenLogoFinanceImage",
  ];
marker(logoFinanceImageTokens);

type LogoBrandImageToken = `${smallBrandName}LogoBrandImage`;
const logoBrandImageTokens: AutogenerateTupleFromUnion<LogoBrandImageToken> = [
  "peugeotLogoBrandImage",
  "dsLogoBrandImage",
  "opelLogoBrandImage",
  "fiatLogoBrandImage",
  "jeepLogoBrandImage",
  "vauxhallLogoBrandImage",
  "alfaromeoLogoBrandImage",
  "citroenLogoBrandImage",
];
marker(logoBrandImageTokens);

export type ResultsElectricAdvantagesImageToken =
  `${smallBrandName}ElectricAdvantageImage`;
const resultsElectricAdvantagesImageTokens: AutogenerateTupleFromUnion<ResultsElectricAdvantagesImageToken> =
  [
    "peugeotElectricAdvantageImage",
    "dsElectricAdvantageImage",
    "opelElectricAdvantageImage",
    "fiatElectricAdvantageImage",
    "jeepElectricAdvantageImage",
    "vauxhallElectricAdvantageImage",
    "alfaromeoElectricAdvantageImage",
    "citroenElectricAdvantageImage",
  ];
marker(resultsElectricAdvantagesImageTokens);

export type ResultsHybridAdvantagesImageToken =
  `${smallBrandName}HybridAdvantageImage`;
const resultsHybridAdvantagesImageTokens: AutogenerateTupleFromUnion<ResultsHybridAdvantagesImageToken> =
  [
    "peugeotHybridAdvantageImage",
    "dsHybridAdvantageImage",
    "opelHybridAdvantageImage",
    "fiatHybridAdvantageImage",
    "jeepHybridAdvantageImage",
    "vauxhallHybridAdvantageImage",
    "alfaromeoHybridAdvantageImage",
    "citroenHybridAdvantageImage",
  ];
marker(resultsHybridAdvantagesImageTokens);

export type ResultsElectricAdvantagesTextTokenB2BVP =
  `${smallBrandName}ElectricAdvantageB2BVP`;
const resultsElectricAdvantagesTextTokenB2BVPs: AutogenerateTupleFromUnion<ResultsElectricAdvantagesTextTokenB2BVP> =
  [
    "peugeotElectricAdvantageB2BVP",
    "dsElectricAdvantageB2BVP",
    "opelElectricAdvantageB2BVP",
    "fiatElectricAdvantageB2BVP",
    "jeepElectricAdvantageB2BVP",
    "vauxhallElectricAdvantageB2BVP",
    "alfaromeoElectricAdvantageB2BVP",
    "citroenElectricAdvantageB2BVP",
  ];
marker(resultsElectricAdvantagesTextTokenB2BVPs);

export type ResultsElectricAdvantagesTextTokenB2BVU =
  `${smallBrandName}ElectricAdvantageB2BVU`;
const resultsElectricAdvantagesTextTokenB2BVUs: AutogenerateTupleFromUnion<ResultsElectricAdvantagesTextTokenB2BVU> =
  [
    "peugeotElectricAdvantageB2BVU",
    "dsElectricAdvantageB2BVU",
    "opelElectricAdvantageB2BVU",
    "fiatElectricAdvantageB2BVU",
    "jeepElectricAdvantageB2BVU",
    "vauxhallElectricAdvantageB2BVU",
    "alfaromeoElectricAdvantageB2BVU",
    "citroenElectricAdvantageB2BVU",
  ];
marker(resultsElectricAdvantagesTextTokenB2BVUs);

export type ResultsElectricAdvantagesTextTokenB2CVN =
  `${smallBrandName}ElectricAdvantageB2CVN`;
const resultsElectricAdvantagesTextTokenB2CVNs: AutogenerateTupleFromUnion<ResultsElectricAdvantagesTextTokenB2CVN> =
  [
    "peugeotElectricAdvantageB2CVN",
    "dsElectricAdvantageB2CVN",
    "opelElectricAdvantageB2CVN",
    "fiatElectricAdvantageB2CVN",
    "jeepElectricAdvantageB2CVN",
    "vauxhallElectricAdvantageB2CVN",
    "alfaromeoElectricAdvantageB2CVN",
    "citroenElectricAdvantageB2CVN",
  ];
marker(resultsElectricAdvantagesTextTokenB2CVNs);

export type ResultsElectricAdvantagesTextTokenB2CVO =
  `${smallBrandName}ElectricAdvantageB2CVO`;
const resultsElectricAdvantagesTextTokenB2CVOs: AutogenerateTupleFromUnion<ResultsElectricAdvantagesTextTokenB2CVO> =
  [
    "peugeotElectricAdvantageB2CVO",
    "dsElectricAdvantageB2CVO",
    "opelElectricAdvantageB2CVO",
    "fiatElectricAdvantageB2CVO",
    "jeepElectricAdvantageB2CVO",
    "vauxhallElectricAdvantageB2CVO",
    "alfaromeoElectricAdvantageB2CVO",
    "citroenElectricAdvantageB2CVO",
  ];
marker(resultsElectricAdvantagesTextTokenB2CVOs);

export type ResultsHybridAdvantagesTextTokenB2BVP =
  `${smallBrandName}HybridAdvantageB2BVP`;
const resultsHybridAdvantagesTextTokenB2BVPs: AutogenerateTupleFromUnion<ResultsHybridAdvantagesTextTokenB2BVP> =
  [
    "peugeotHybridAdvantageB2BVP",
    "dsHybridAdvantageB2BVP",
    "opelHybridAdvantageB2BVP",
    "fiatHybridAdvantageB2BVP",
    "jeepHybridAdvantageB2BVP",
    "vauxhallHybridAdvantageB2BVP",
    "alfaromeoHybridAdvantageB2BVP",
    "citroenHybridAdvantageB2BVP",
  ];
marker(resultsHybridAdvantagesTextTokenB2BVPs);

export type ResultsHybridAdvantagesTextTokenB2BVU =
  `${smallBrandName}HybridAdvantageB2BVU`;
const resultsHybridAdvantagesTextTokenB2BVUs: AutogenerateTupleFromUnion<ResultsHybridAdvantagesTextTokenB2BVU> =
  [
    "peugeotHybridAdvantageB2BVU",
    "dsHybridAdvantageB2BVU",
    "opelHybridAdvantageB2BVU",
    "fiatHybridAdvantageB2BVU",
    "jeepHybridAdvantageB2BVU",
    "vauxhallHybridAdvantageB2BVU",
    "alfaromeoHybridAdvantageB2BVU",
    "citroenHybridAdvantageB2BVU",
  ];
marker(resultsHybridAdvantagesTextTokenB2BVUs);

export type ResultsHybridAdvantagesTextTokenB2CVN =
  `${smallBrandName}HybridAdvantageB2CVN`;
const resultsHybridAdvantagesTextTokenB2CVNs: AutogenerateTupleFromUnion<ResultsHybridAdvantagesTextTokenB2CVN> =
  [
    "peugeotHybridAdvantageB2CVN",
    "dsHybridAdvantageB2CVN",
    "opelHybridAdvantageB2CVN",
    "fiatHybridAdvantageB2CVN",
    "jeepHybridAdvantageB2CVN",
    "vauxhallHybridAdvantageB2CVN",
    "alfaromeoHybridAdvantageB2CVN",
    "citroenHybridAdvantageB2CVN",
  ];
marker(resultsHybridAdvantagesTextTokenB2CVNs);

export type ResultsHybridAdvantagesTextTokenB2CVO =
  `${smallBrandName}HybridAdvantageB2CVO`;
const resultsHybridAdvantagesTextTokenB2CVOs: AutogenerateTupleFromUnion<ResultsHybridAdvantagesTextTokenB2CVO> =
  [
    "peugeotHybridAdvantageB2CVO",
    "dsHybridAdvantageB2CVO",
    "opelHybridAdvantageB2CVO",
    "fiatHybridAdvantageB2CVO",
    "jeepHybridAdvantageB2CVO",
    "vauxhallHybridAdvantageB2CVO",
    "alfaromeoHybridAdvantageB2CVO",
    "citroenHybridAdvantageB2CVO",
  ];
marker(resultsHybridAdvantagesTextTokenB2CVOs);

type LegalDisclaimerTextToken = `${smallBrandName}LegalDisclaimer`;
const legalDisclaimerTextTokens: AutogenerateTupleFromUnion<LegalDisclaimerTextToken> =
  [
    "peugeotLegalDisclaimer",
    "dsLegalDisclaimer",
    "opelLegalDisclaimer",
    "fiatLegalDisclaimer",
    "jeepLegalDisclaimer",
    "vauxhallLegalDisclaimer",
    "alfaromeoLegalDisclaimer",
    "citroenLegalDisclaimer",
  ];
marker(legalDisclaimerTextTokens);

export type Color = `#${string}` | `rgb(${number}, ${number}, ${number})`;

export interface Theme {
  class: `${smallBrandName}-theme`;
  accentColorInTitles: boolean;
  toolbarPrimary: boolean;
  logoFinance: LogoFinanceImageToken;
  logoFinanceDescription: string;
  logoBrand: LogoBrandImageToken;
  logoBrandDescription: string;
  logoMoveToFrontPage: `frontpage/${string}`;
  logoMoveToRefinePage: `finalpage/${string}`;
  resultsElectricAdvantagesImageToken: ResultsElectricAdvantagesImageToken;
  resultsElectricAdvantagesTextTokenB2BVP: ResultsElectricAdvantagesTextTokenB2BVP;
  resultsElectricAdvantagesTextTokenB2BVU: ResultsElectricAdvantagesTextTokenB2BVU;
  resultsElectricAdvantagesTextTokenB2CVN: ResultsElectricAdvantagesTextTokenB2CVN;
  resultsElectricAdvantagesTextTokenB2CVO: ResultsElectricAdvantagesTextTokenB2CVO;
  resultsHybridAdvantagesImageToken: ResultsHybridAdvantagesImageToken;
  resultsHybridAdvantagesTextTokenB2BVP: ResultsHybridAdvantagesTextTokenB2BVP;
  resultsHybridAdvantagesTextTokenB2BVU: ResultsHybridAdvantagesTextTokenB2BVU;
  resultsHybridAdvantagesTextTokenB2CVN: ResultsHybridAdvantagesTextTokenB2CVN;
  resultsHybridAdvantagesTextTokenB2CVO: ResultsHybridAdvantagesTextTokenB2CVO;
  legalDisclaimer: LegalDisclaimerTextToken;
  mainFont: string;
  mainColor: Color;
  accentColor: `#${string}` | `rgb(${number}, ${number}, ${number})`;
}

export const PeugotTheme: Theme = {
  accentColorInTitles: true,
  class: "peugeot-theme",
  toolbarPrimary: true,
  logoFinance: "peugeotLogoFinanceImage",
  logoFinanceDescription: "Stellantis Financial Services",
  logoBrand: "peugeotLogoBrandImage",
  logoBrandDescription: "Peugeot logo",
  logoMoveToFrontPage: "frontpage/logo_Peugeot_Front_Page.webp",
  logoMoveToRefinePage: "finalpage/logo_Peugeot_ELEC_Page_finale.webp",
  resultsElectricAdvantagesImageToken: "peugeotElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "peugeotElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "peugeotElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "peugeotElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "peugeotElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "peugeotHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "peugeotHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "peugeotHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "peugeotHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "peugeotHybridAdvantageB2CVO",
  legalDisclaimer: "peugeotLegalDisclaimer",
  mainFont: "peugeot-new-regular",
  mainColor: "#000",
  accentColor: "rgb(0, 163, 224)",
};
const CitroenTheme: Theme = {
  accentColorInTitles: true,
  class: "citroen-theme",
  toolbarPrimary: true,
  logoFinance: "citroenLogoFinanceImage",
  logoFinanceDescription: "Stellantis Financial Services",
  logoBrand: "citroenLogoBrandImage",
  logoBrandDescription: "Citroën logo",
  logoMoveToFrontPage: "frontpage/logo_Citroen_Front_Page.webp",
  logoMoveToRefinePage: "finalpage/logo_Citroen_elec_final_page.webp",
  resultsElectricAdvantagesImageToken: "citroenElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "citroenElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "citroenElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "citroenElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "citroenElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "citroenHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "citroenHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "citroenHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "citroenHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "citroenHybridAdvantageB2CVO",
  legalDisclaimer: "citroenLegalDisclaimer",
  mainFont: "citroenregular",
  mainColor: "rgb(25, 120, 150)",
  accentColor: "rgb(235, 100, 40)",
};
const DSTheme: Theme = {
  accentColorInTitles: true,
  class: "ds-theme",
  toolbarPrimary: false,
  logoFinance: "dsLogoFinanceImage",
  logoFinanceDescription: "Stellantis Financial Services",
  logoBrand: "dsLogoBrandImage",
  logoBrandDescription: "DS logo",
  logoMoveToFrontPage: "frontpage/logo_DS_front_page.webp",
  logoMoveToRefinePage: "finalpage/logo_DS_final_page.webp",
  resultsElectricAdvantagesImageToken: "dsElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "dsElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "dsElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "dsElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "dsElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "dsHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "dsHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "dsHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "dsHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "dsHybridAdvantageB2CVO",
  legalDisclaimer: "dsLegalDisclaimer",
  mainFont: "DSAutomobiles-Regular",
  mainColor: "rgb(181, 25, 83)",
  accentColor: "rgb(29, 23, 23)",
};
const AlfaRomeoTheme: Theme = {
  accentColorInTitles: true,
  class: "alfaromeo-theme",
  toolbarPrimary: true,
  logoFinance: "alfaromeoLogoFinanceImage",
  logoFinanceDescription: "alfaromeo logo",
  logoBrand: "alfaromeoLogoBrandImage",
  logoBrandDescription: "Alfa Romeo logo",
  logoMoveToFrontPage: "frontpage/logo_alfa_romeo.webp",
  logoMoveToRefinePage: "finalpage/logo_alfa_romeo.webp",
  resultsElectricAdvantagesImageToken: "alfaromeoElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "alfaromeoElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "alfaromeoElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "alfaromeoElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "alfaromeoElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "alfaromeoHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "alfaromeoHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "alfaromeoHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "alfaromeoHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "alfaromeoHybridAdvantageB2CVO",
  legalDisclaimer: "alfaromeoLegalDisclaimer",
  mainFont: "sequel-100-black-45",
  mainColor: "#1C1F2A",
  accentColor: "#BA0816",
};
const JeepTheme: Theme = {
  accentColorInTitles: true,
  class: "jeep-theme",
  toolbarPrimary: true,
  logoFinance: "jeepLogoFinanceImage",
  logoFinanceDescription: "Jeep logo",
  logoBrand: "jeepLogoBrandImage",
  logoBrandDescription: "Jeep logo",
  logoMoveToFrontPage: "frontpage/jeep.webp",
  logoMoveToRefinePage: "finalpage/jeep.webp",
  resultsElectricAdvantagesImageToken: "jeepElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "jeepElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "jeepElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "jeepElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "jeepElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "jeepHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "jeepHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "jeepHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "jeepHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "jeepHybridAdvantageB2CVO",
  legalDisclaimer: "jeepLegalDisclaimer",
  mainFont: "dinpro-regular",
  mainColor: "#000000",
  accentColor: "#0669f7",
};
const FiatTheme: Theme = {
  accentColorInTitles: true,
  class: "fiat-theme",
  toolbarPrimary: true,
  logoFinance: "fiatLogoFinanceImage",
  logoFinanceDescription: "Stellantis Financial Services",
  logoBrand: "fiatLogoBrandImage",
  logoBrandDescription: "Fiat logo",
  logoMoveToFrontPage: "frontpage/logo_fiat_black.webp",
  logoMoveToRefinePage: "finalpage/logo_fiat_black.webp",
  resultsElectricAdvantagesImageToken: "fiatElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "fiatElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "fiatElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "fiatElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "fiatElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "fiatHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "fiatHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "fiatHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "fiatHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "fiatHybridAdvantageB2CVO",
  legalDisclaimer: "fiatLegalDisclaimer",
  mainFont: "poppins-regular",
  mainColor: "#000000",
  accentColor: "rgb(229, 21, 44)",
};
const OpelTheme: Theme = {
  accentColorInTitles: false,
  class: "opel-theme",
  toolbarPrimary: true,
  logoFinance: "opelLogoFinanceImage",
  logoFinanceDescription: "Opel logo",
  logoBrand: "opelLogoBrandImage",
  logoBrandDescription: "OV Finance",
  logoMoveToFrontPage: "frontpage/logo_Opel_front_page.webp",
  logoMoveToRefinePage: "finalpage/logo_Opel_final_page.webp",
  resultsElectricAdvantagesImageToken: "opelElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "opelElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "opelElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "opelElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "opelElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "opelHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "opelHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "opelHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "opelHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "opelHybridAdvantageB2CVO",
  legalDisclaimer: "opelLegalDisclaimer",
  mainFont: "OpelNextW01-Regular",
  mainColor: "rgb(247, 255, 20)",
  accentColor: "rgb(180, 187, 191)",
};
const VauxhallTheme: Theme = {
  accentColorInTitles: true,
  class: "vauxhall-theme",
  toolbarPrimary: true,
  logoFinance: "vauxhallLogoFinanceImage",
  logoFinanceDescription: "Vauxhall logo",
  logoBrand: "vauxhallLogoBrandImage",
  logoBrandDescription: "OV Finance",
  logoMoveToFrontPage: "frontpage/Switch it up.webp",
  logoMoveToRefinePage: "finalpage/logo_vauxhall_final_page.webp",
  resultsElectricAdvantagesImageToken: "vauxhallElectricAdvantageImage",
  resultsElectricAdvantagesTextTokenB2BVP: "vauxhallElectricAdvantageB2BVP",
  resultsElectricAdvantagesTextTokenB2BVU: "vauxhallElectricAdvantageB2BVU",
  resultsElectricAdvantagesTextTokenB2CVN: "vauxhallElectricAdvantageB2CVN",
  resultsElectricAdvantagesTextTokenB2CVO: "vauxhallElectricAdvantageB2CVO",
  resultsHybridAdvantagesImageToken: "vauxhallHybridAdvantageImage",
  resultsHybridAdvantagesTextTokenB2BVP: "vauxhallHybridAdvantageB2BVP",
  resultsHybridAdvantagesTextTokenB2BVU: "vauxhallHybridAdvantageB2BVU",
  resultsHybridAdvantagesTextTokenB2CVN: "vauxhallHybridAdvantageB2CVN",
  resultsHybridAdvantagesTextTokenB2CVO: "vauxhallHybridAdvantageB2CVO",
  legalDisclaimer: "vauxhallLegalDisclaimer",
  mainFont: "VauxhallNeue-Regular",
  mainColor: "rgb(255, 0, 30)",
  accentColor: "rgb(0, 0, 55)",
};
@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private _theme = new ReplaySubject<Theme | null>(1);
  $theme = this._theme.asObservable();
  currentTheme: Theme | null = null;

  constructor(public userParamsService: UserParamsService) {
    this.userParamsService.onParamsChange$.subscribe(() => {
      this.currentTheme = this.getNewTheme();
      this._theme.next(this.currentTheme);
    });
  }

  // eslint-disable-next-line complexity
  getNewTheme(): Theme {
    switch (this.userParamsService.params.brand) {
      case Brand.AP:
        return PeugotTheme;
      case Brand.AC:
        return CitroenTheme;
      case Brand.DS:
        return DSTheme;
      case Brand.OV:
        if (this.userParamsService.params.country === "GB")
          return VauxhallTheme;
        else return OpelTheme;
      case Brand.AR:
        return AlfaRomeoTheme;
      case Brand.FT:
        return FiatTheme;
      case Brand.JE:
        return JeepTheme;
      default:
        exhaustive(this.userParamsService.params.brand);
    }
  }
}
