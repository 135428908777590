import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { captureException, captureMessage } from "@sentry/angular-ivy";
import { OAuthErrorEvent, OAuthService } from "angular-oauth2-oidc";
import { ObjectUnsubscribedError, switchMap } from "rxjs";
import localEnv from "src/environments/localenv";
import { ONE_THOUSAND_MS } from "src/shared/magic-numbers";
import {
  enforceString,
  isNullish,
  readAttributeFromUnknown,
  throwIfNullish,
  unknownToString,
} from "src/shared/utils";

const GENERIC_ERROR_MESSAGE =
  "Something went wrong. The administrators have been notified and should resolve this error quickly. Contact them if you need immediate assistance.";

export function handleErrorWithSnackBar(
  message: string,
  snackBarInstance: MatSnackBar,
) {
  snackBarInstance.open(message, "Close");
}

function handleUncaughtPromiseError(
  error: unknown,
  onCaught: (error: unknown) => void,
) {
  (
    readAttributeFromUnknown(error, "promise", "object") as Promise<unknown>
  ).catch((uncaughtError) => {
    // HttpErrorResponse are not instances of Error
    if (
      uncaughtError instanceof Error ||
      uncaughtError instanceof HttpErrorResponse
    )
      onCaught(uncaughtError);
    else {
      const reconstructedError = new Error(unknownToString(uncaughtError), {
        cause: GENERIC_ERROR_MESSAGE,
      });
      reconstructedError.stack = readAttributeFromUnknown(
        error,
        "stack",
        "string",
      );
      onCaught(reconstructedError);
    }
  });
}

// CF https://sentry.io/organizations/stellantis/issues/3904372521/events/ff27e32753b747c98d3806d36852e1b2/?project=4504537351651328&referrer=alert-rule-issue-list
const CHUNK_FAILED_MESSAGE = /Loading chunk [\d]+ failed/u;

export enum GlobalErrorSelfRepairCause {
  BACKEND_SLOW_LOGOUT = "BACKEND_SLOW_LOGOUT",
  BACKEND_IS_TRUSTED_ERROR = "BACKEND_IS_TRUSTED_ERROR",
}

interface BaseErrorHandler {
  selfReparable: boolean;
  appAction: "reload-page" | "navigate-home" | "do-nothing";
}
type ErrorHandler =
  | ({
      showSnackBar: true;
      snackBarMessage?: string;
    } & BaseErrorHandler)
  | ({
      showSnackBar: false;
    } & BaseErrorHandler);

type ErrorHandlerRule = {
  rule: (error: unknown) => boolean;
  handler: ErrorHandler | ((error: unknown) => ErrorHandler);
  context?: {
    name: string;
    description: string;
  };
  priority: number;
};

export function applyHandlerRule(
  errorHandlerRule: ErrorHandlerRule,
  error: unknown,
) {
  return typeof errorHandlerRule.handler === "function"
    ? errorHandlerRule.handler(error)
    : errorHandlerRule.handler;
}

const ErrorHandlerRules = [
  {
    rule: (error: unknown) => {
      if (error instanceof OAuthErrorEvent) {
        switch (error.type) {
          case "silent_refresh_timeout":
          case "token_refresh_error":
            return false;
          default:
            return true;
        }
      }
      return false;
    },
    handler: {
      showSnackBar: false,
      selfReparable: false,
      appAction: "do-nothing",
    },
    context: {
      name: "OAuth error",
      description:
        "Do nothing, show nothing in global error handler. The OAuth service will handle it.",
    },
    priority: 10,
  },
  {
    rule: (error: unknown) =>
      error instanceof HttpErrorResponse &&
      error.status === 0 &&
      error.ok === false,
    handler: {
      showSnackBar: false,
      selfReparable: true,
      appAction: "do-nothing",
    },
    context: {
      name: "HTTP error with status 0",
      description:
        "Most lilkely the internet is disconnected, or it is a CORS error.",
    },
    priority: 10,
  },
  {
    rule: (error: unknown) =>
      error instanceof Error && CHUNK_FAILED_MESSAGE.test(error.message),
    handler: {
      showSnackBar: false,
      selfReparable: true,
      appAction: "reload-page",
    },
    context: {
      name: "chunkFailed",
      description:
        "The backend takes time to actually log out when the user logs out. This error shows when this is the case.",
    },
    priority: 10,
  },
  {
    rule: (error: unknown) => error instanceof ObjectUnsubscribedError,
    handler: {
      showSnackBar: false,
      selfReparable: true,
      appAction: "do-nothing",
    },
    context: {
      name: "objectUnsubscribedError",
      description:
        "ObjectUnsubscribedError is thrown when an object is used after the rxjs subscription is closed.",
    },
    priority: 10,
  },
  {
    rule: (error: unknown) =>
      error instanceof Error &&
      error.cause === GlobalErrorSelfRepairCause.BACKEND_IS_TRUSTED_ERROR,
    handler: {
      showSnackBar: true,
      selfReparable: true,
      appAction: "reload-page",
    },
    context: {
      name: "backendIsTrustedError",
      description:
        "This error has been thrown once and is not reproducible. It is likely a backend issue with the LDAP. The page auto-reloads.",
    },
    priority: 10,
  },
  {
    rule: (error: unknown) =>
      error instanceof Error &&
      !isNullish(error.cause) &&
      typeof error.cause === "string",
    handler: (error: unknown) => ({
      showSnackBar: true,
      snackBarMessage:
        error instanceof Error ? String(error.cause) : unknownToString(error),
      selfReparable: false,
      appAction: "do-nothing",
    }),
    context: {
      name: "hasCauseString",
      description:
        "Any error declared with new Error(message, { cause: string }) will be handled this way if there is no other rule that matches.",
    },
    priority: 1,
  },
  {
    rule: () => true,
    priority: 0,
    handler: {
      showSnackBar: true,
      selfReparable: false,
      appAction: "do-nothing",
    },
    context: {
      name: "genericError",
      description:
        "This error is not handled by any rule. It will be handled as a generic error.",
    },
  },
] satisfies ErrorHandlerRule[];

function isUncaughtPromiseError(error: unknown) {
  return readAttributeFromUnknown(error, "promise", "object") !== undefined;
}

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerWithSentry {
  errorEmitter = new EventEmitter<Error>();

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private zone: NgZone,
    private translateService: TranslateService,
    private oAuthService: OAuthService,
  ) {
    this.oAuthService.events
      .pipe(
        // eslint-disable-next-line complexity
        switchMap(async (event) => {
          if (event instanceof OAuthErrorEvent)
            await Promise.resolve(this.handleError(event));
        }),
      )
      .subscribe();
  }

  resolveErrorHandlerRule(error: unknown): ErrorHandlerRule {
    const matchedRules = Object.values(ErrorHandlerRules)
      .sort((a, b) => b.priority - a.priority)
      .map((rule) => [rule, rule.rule(error)] as const);

    if (localEnv.debugLogs) {
      // eslint-disable-next-line no-console
      console.table(
        matchedRules.map(([rule, ruleValue]) => ({
          name: rule.context.name,
          priority: rule.priority,
          value: ruleValue,
        })),
      );
    }

    const matchedRule = matchedRules.filter(([, ruleValue]) => ruleValue)[0][0];

    if (matchedRule) return matchedRule;

    throw new Error("No rule matches this error, this is not normal.");
  }

  handleError(error: unknown) {
    const errorHandlerRule = this.resolveErrorHandlerRule(error);
    if (isUncaughtPromiseError(error)) {
      handleUncaughtPromiseError(error, this.handleError.bind(this));
      return;
    }

    const errorHandler = applyHandlerRule(errorHandlerRule, error);

    if (localEnv.debugLogs) {
      // eslint-disable-next-line no-console
      console.log("GlobalErrorHandlerWithSentry.handleError.error", error);
      // eslint-disable-next-line no-console
      console.log(
        "GlobalErrorHandlerWithSentry.handleError.errorHandlerRule",
        errorHandlerRule,
      );
      // eslint-disable-next-line no-console
      console.log(
        "GlobalErrorHandlerWithSentry.handleError.errorHandler",
        errorHandler,
      );
    }

    if (errorHandler.showSnackBar) {
      this.zone.run(() => {
        const snackBarInstance = this._snackBar;
        const messageOrTranslationToken = throwIfNullish(
          errorHandler.snackBarMessage ??
            readAttributeFromUnknown(
              error,
              "message",
              "string",
              GENERIC_ERROR_MESSAGE,
            ),
          { variableName: "errorHandler.snackBarMessage" },
        );
        const message = enforceString(
          this.translateService.instant(messageOrTranslationToken),
        );
        handleErrorWithSnackBar(message, snackBarInstance);
      });
    }

    if (errorHandler.selfReparable) {
      const errorMessage = `Caught self-repairable global error: ${unknownToString(
        error,
      )}`;
      captureMessage(errorMessage, {
        tags: {
          location: "GlobalErrorHandlerWithSentry",
          ...errorHandlerRule.context,
          appAction: errorHandler.appAction,
        },
        level: "info",
      });
      console.error(errorMessage);
    } else {
      captureException(error, {
        tags: {
          location: "GlobalErrorHandlerWithSentry",
          ...errorHandlerRule.context,
          appAction: errorHandler.appAction,
        },
      });
      console.error(error);
    }

    if (errorHandler.appAction === "reload-page") {
      setTimeout(() => {
        window.location.reload();
      }, ONE_THOUSAND_MS);
    } else if (errorHandler.appAction === "navigate-home") {
      setTimeout(() => {
        void this.zone.run(async () => {
          await this.router.navigate(["/"]);
        });
      }, ONE_THOUSAND_MS);
    } else console.warn("No app action specified for error.");
  }
}
