import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { of } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { authCodeFlowConfig } from "src/app/services/auth.config";

import { AuthService } from "src/app/services/auth.service";
import localEnv from "src/environments/localenv";
import { isSome } from "src/shared/utils.monad";

export const canActivateAuthAndForceLogin: CanActivateFn = () => {
  if (localEnv.debugLogs)
    // eslint-disable-next-line no-console
    console.log("Running canActivateAuthAndForceLogin");

  const authService = inject(AuthService);
  if (!isSome(authCodeFlowConfig)) return of(true);

  return authService.isDoneLoading$.pipe(
    filter((isDone) => isDone),
    switchMap(() => authService.isAuthenticated$),
    tap((isAuthenticated) => {
      if (localEnv.debugLogs)
        // eslint-disable-next-line no-console
        console.log(`isAuthenticated: ${isAuthenticated}`);

      if (isAuthenticated) return isAuthenticated;
      authService.login();

      return false;
    }),
  );
};
