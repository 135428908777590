import { Component, HostBinding, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { authCodeFlowConfig } from "src/app/services/auth.config";
import { AuthService } from "src/app/services/auth.service";
import { Theme, ThemeService } from "src/app/services/theme.service";
import { UserParamsService } from "src/app/services/user-params.service";
import { stringifyEvent } from "src/app/utils.angular";
import { environment } from "src/environments/environment";
import localEnv from "src/environments/localenv";
import { isSome } from "src/shared/utils.monad";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  theme: Theme | null;
  @HostBinding("@.disabled")
  public animationsDisabled = environment.disableAnimations;
  // TODO: remove this when transition to oauth is complete
  public samlMethod: "saml-link" | "oauthService" = isSome(authCodeFlowConfig)
    ? "oauthService"
    : "saml-link";

  constructor(
    public userParamsService: UserParamsService,
    public themeService: ThemeService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private renderer: Renderer2,
    public authService: AuthService,
  ) {
    this.theme = themeService.currentTheme;
    if (localEnv.routerLogs) {
      this.router.events.subscribe((event) => {
        /* eslint-disable no-console */
        console.log(`Router: ${stringifyEvent(event)}`);
        console.log(event);
        /* eslint-enable no-console */
      });
    }
  }

  async ngOnInit() {
    if (localEnv.debugLogs)
      // eslint-disable-next-line no-console
      console.log("Init app component");

    if (this.samlMethod === "oauthService") {
      this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          if (localEnv.debugLogs)
            // eslint-disable-next-line no-console
            console.log("Loading user info");
          this.userParamsService.loadUserInfo(this.samlMethod);
        }
      });
    } else this.userParamsService.loadUserInfo(this.samlMethod);

    if (!environment.disableGTM) {
      const gtmAdded = await this.gtmService.addGtmToDom();
      if (!gtmAdded) throw new Error("GTM not added");
    }
    void this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };

        void this.gtmService.pushTag(gtmTag);
      }
    });
    this.themeService.$theme.subscribe((newTheme) => {
      if (newTheme === null) return;
      if (this.theme !== null)
        this.renderer.removeClass(document.body, this.theme.class);
      this.renderer.addClass(document.body, newTheme.class);

      this.theme = newTheme;
    });

    if (localEnv.debugLogs)
      // eslint-disable-next-line no-console
      console.log("Init app component done");
  }
}
