import { HttpErrorResponse } from "@angular/common/http";
import { isEqual } from "lodash-es";
import { GlobalErrorSelfRepairCause } from "src/app/global-error-handler.service";
import { readAttributeFromUnknown } from "src/shared/utils";

const NOT_LOGGED_IN_BACKEND_ERROR = "logged in is ko!";
export const USER_INFO_FETCH_ERROR =
  "Error while fetching user info. If you have just logged out from the application, please wait for a few minutes and reload the page to reconnect. If the error persists, contact the application's administrator.";

export const BACKEND_ERROR_HANDLER_RULES = [
  {
    rule: (error: HttpErrorResponse) => {
      const textInError = readAttributeFromUnknown(
        error.error,
        "text",
        "string",
      );

      return textInError === NOT_LOGGED_IN_BACKEND_ERROR;
    },
    handler: () =>
      new Error(USER_INFO_FETCH_ERROR, {
        cause: GlobalErrorSelfRepairCause.BACKEND_SLOW_LOGOUT,
      }),
    priority: 1,
  },
  {
    rule: (error: HttpErrorResponse) =>
      isEqual(error.error, { isTrusted: true }),
    handler: () =>
      new Error(USER_INFO_FETCH_ERROR, {
        cause: GlobalErrorSelfRepairCause.BACKEND_IS_TRUSTED_ERROR,
      }),
    priority: 1,
  },
] satisfies {
  rule: (error: HttpErrorResponse) => boolean;
  handler: () => Error;
  priority: number;
}[];
