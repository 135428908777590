import { Injectable } from "@angular/core";
import { setUser } from "@sentry/angular-ivy";
import { RRDIRepository } from "src/app/repositories/rrdi-repository";
import { UserParamsService } from "src/app/services/user-params.service";

@Injectable({
  providedIn: "root",
})
export class SentryService {
  constructor(private userParamsService: UserParamsService) {
    this.userParamsService.onParamsChange$.subscribe((params) => {
      setUser({
        id: params.uid,
        country: params.country,
        currency: params.currency,
        language: params.language,
        brand: params.brand,
        brandType: params.brandTypes,
        rrdi7: params.rrdi7,
        dealershipName:
          RRDIRepository.getDealershipName(params.rrdi7) ??
          "UNKNOWN_DEALERSHIP",
      });
    });
  }
}
