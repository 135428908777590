/* eslint-disable max-lines */

import { Brand, BrandsFormatting } from "src/shared/brands.definitions";
import { ListItem } from "src/shared/common-types";
import { HUNDRED_KILOMETERS } from "src/shared/magic-numbers";
import {
  AutogenerateTupleFromUnion,
  DecimalFormat,
} from "src/shared/utils.types";
import {
  ClientType,
  ComparableVehicleEnergy,
  VehicleGenre,
} from "src/shared/vehicle.definitions";
// 1 gallons = 4.54609 liter
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { getObjectKeys, throwIfNullish } from "src/shared/utils";
import { uppercaseFirstLetter } from "src/shared/utils.string";
import { SafeParseReturnType, z } from "zod";

const galonsPerLiter = 4.54609;
// 1 mile = 1.60934 km
const milesPerKm = 1.60934;
export type StandardUnit =
  | "whole"
  | "/month"
  | "L/100km"
  | "L"
  | "/L"
  | "g/km"
  | "kWh/100km"
  | "kWh"
  | "/kWh"
  | "/year"
  | "km";
const standardUnitList: AutogenerateTupleFromUnion<
  Exclude<StandardUnit, "whole">
> = [
  "/month",
  "L/100km",
  "L",
  "/L",
  "g/km",
  "kWh/100km",
  "kWh",
  "/kWh",
  "/year",
  "km",
];
marker(standardUnitList);

export type StandardUnitsDefinitions = Record<
  StandardUnit,
  { multiplier: number; invert: boolean; numberFormat: DecimalFormat }
>;

export const defaultStandardUnits: StandardUnitsDefinitions = {
  "whole": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
  "/month": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
  "/year": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
  "L/100km": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-1",
  },
  "L": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
  "/L": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.2-2",
  },
  "g/km": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-2",
  },
  "kWh/100km": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-2",
  },
  "kWh": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
  "/kWh": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-2",
  },
  "km": {
    multiplier: 1,
    invert: false,
    numberFormat: "1.0-0",
  },
};
export const standardUnitDefinitionsUk: StandardUnitsDefinitions = {
  ...defaultStandardUnits,
  "L/100km": {
    multiplier: (HUNDRED_KILOMETERS * galonsPerLiter) / milesPerKm,
    invert: true,
    numberFormat: "1.0-0",
  },
  "L": {
    multiplier: galonsPerLiter,
    invert: true,
    numberFormat: "1.0-0",
  },
  "kWh/100km": {
    multiplier: HUNDRED_KILOMETERS / milesPerKm,
    invert: true,
    numberFormat: "1.0-0",
  },
  "km": {
    multiplier: 1 / milesPerKm,
    invert: false,
    numberFormat: "1.0-0",
  },
};

type CustomCurrency = "TL"; // For Turkey, Stellantis prefers to show "TL" instead of the currency symbol

type StandardCurrency =
  | "AED"
  | "AFN"
  | "ALL"
  | "AMD"
  | "ANG"
  | "AOA"
  | "ARS"
  | "AUD"
  | "AWG"
  | "AZN"
  | "BAM"
  | "BBD"
  | "BDT"
  | "BGN"
  | "BHD"
  | "BIF"
  | "BMD"
  | "BND"
  | "BOB"
  | "BOV"
  | "BRL"
  | "BSD"
  | "BTN"
  | "BWP"
  | "BYN"
  | "BZD"
  | "CAD"
  | "CDF"
  | "CHE"
  | "CHF"
  | "CHW"
  | "CLF"
  | "CLP"
  | "COP"
  | "COU"
  | "CRC"
  | "CUC"
  | "CUP"
  | "CVE"
  | "CZK"
  | "DJF"
  | "DKK"
  | "DOP"
  | "DZD"
  | "EGP"
  | "ERN"
  | "ETB"
  | "EUR"
  | "FJD"
  | "FKP"
  | "GBP"
  | "GEL"
  | "GHS"
  | "GIP"
  | "GMD"
  | "GNF"
  | "GTQ"
  | "GYD"
  | "HKD"
  | "HNL"
  | "HRK"
  | "HTG"
  | "HUF"
  | "IDR"
  | "ILS"
  | "INR"
  | "IQD"
  | "IRR"
  | "ISK"
  | "JMD"
  | "JOD"
  | "JPY"
  | "KES"
  | "KGS"
  | "KHR"
  | "KMF"
  | "KPW"
  | "KRW"
  | "KWD"
  | "KYD"
  | "KZT"
  | "LAK"
  | "LBP"
  | "LKR"
  | "LRD"
  | "LSL"
  | "LYD"
  | "MAD"
  | "MDL"
  | "MGA"
  | "MKD"
  | "MMK"
  | "MNT"
  | "MOP"
  | "MRU"
  | "MUR"
  | "MVR"
  | "MWK"
  | "MXN"
  | "MXV"
  | "MYR"
  | "MZN"
  | "NAD"
  | "NGN"
  | "NIO"
  | "NOK"
  | "NPR"
  | "NZD"
  | "OMR"
  | "PAB"
  | "PEN"
  | "PGK"
  | "PHP"
  | "PKR"
  | "PLN"
  | "PYG"
  | "QAR"
  | "RON"
  | "RSD"
  | "CNY"
  | "RUB"
  | "RWF"
  | "SAR"
  | "SBD"
  | "SCR"
  | "SDG"
  | "SEK"
  | "SGD"
  | "SHP"
  | "SLL"
  | "SOS"
  | "SRD"
  | "SSP"
  | "STN"
  | "SVC"
  | "SYP"
  | "SZL"
  | "THB"
  | "TJS"
  | "TMT"
  | "TND"
  | "TOP"
  | "TRY"
  | "TTD"
  | "TWD"
  | "TZS"
  | "UAH"
  | "UGX"
  | "USD"
  | "USN"
  | "UYI"
  | "UYU"
  | "UYW"
  | "UZS"
  | "VED"
  | "VES"
  | "VND"
  | "VUV"
  | "WST"
  | "XAF"
  | "XAG"
  | "XAU"
  | "XBA"
  | "XBB"
  | "XBC"
  | "XBD"
  | "XCD"
  | "XDR"
  | "XOF"
  | "XPD"
  | "XPF"
  | "XPT"
  | "XSU"
  | "XTS"
  | "XUA"
  | "XXX"
  | "YER"
  | "ZAR"
  | "ZMW"
  | "ZWL";

export type Currency = CustomCurrency | StandardCurrency;

export type CurrencyDisplay = "symbol" | "code" | "symbol-narrow";

export type BERegion = "BRUSSELS/WALLONIA" | "FLANDERS";
export const BERegionsList: AutogenerateTupleFromUnion<BERegion> = [
  "BRUSSELS/WALLONIA",
  "FLANDERS",
];
marker(BERegionsList);

export type Region = BERegion;

/**
 * RegionSet is a Set that can be initialized only once and constrained to one Region
 */
export class RegionsSet<T extends Region> {
  private _regionsSet = new Set<T>();

  constructor(regions: T[]) {
    this._regionsSet = new Set(regions);
  }

  static empty(): RegionsSet<Region> {
    return new RegionsSet([]);
  }

  public get isEmpty(): boolean {
    return this._regionsSet.size === 0;
  }

  public get first(): T | undefined {
    return [...this._regionsSet.values()][0];
  }

  public get values(): T[] {
    return Array.from(this._regionsSet);
  }

  public has(region: T): boolean {
    return this._regionsSet.has(region);
  }
}

export const regionsB2CBE = new RegionsSet(["BRUSSELS/WALLONIA", "FLANDERS"]);
export const regionsB2BBE = new RegionsSet(["BRUSSELS/WALLONIA"]);

export type FinancialTechnique =
  | "financialTechnique.lease"
  | "financialTechnique.credit";
export interface FinancialTechniqueConfiguration {
  technique: FinancialTechnique;
  default?: boolean;
}

export const CountriesMap = {
  FR: "france",
  ES: "spain",
  GB: "england",
  BE: "belgium",
  DE: "germany",
  IT: "italy",
  AT: "austria",
  PL: "poland",
  NL: "netherlands",
  TR: "turkey",
  PT: "portugal",
} as const;
export type Country = keyof typeof CountriesMap;
type CountryName = (typeof CountriesMap)[keyof typeof CountriesMap];

const countriesTokensList: AutogenerateTupleFromUnion<CountryName> = [
  "france",
  "spain",
  "england",
  "belgium",
  "germany",
  "italy",
  "austria",
  "poland",
  "netherlands",
  "turkey",
  "portugal",
];
marker(countriesTokensList);
export function resolveCountryCode(
  value: string,
): SafeParseReturnType<Country, Country> {
  const countryCodes = getObjectKeys(CountriesMap) as unknown as readonly [
    Country,
    ...Country[],
  ];
  const countrySchema = z.enum(countryCodes);
  return countrySchema.safeParse(value);
}

// TODO: Missing a way to describe that only B2B is available, like in Brand.FI
export interface CountryConfiguration {
  value: Country;
  brand: Brand;
  currency: Currency;
  currencyDisplay: CurrencyDisplay;
  standardUnitDefinitions: StandardUnitsDefinitions;
  valueDisplay: CountryName;
  financialTechniques: FinancialTechniqueConfiguration[];
  defaultClientType: ClientType;
  // TODO: RegionsB2C and B2B add anormal coupling to B2C / B2B #no-scattered-country-configuration
  regionsB2C: RegionsSet<Region>;
  regionsB2B: RegionsSet<Region>;
  vehicleGenresListElectric: VehicleGenre[];
  B2CVOVN: boolean | Record<ComparableVehicleEnergy, boolean>;
}

// TODO: OR make this into the source of truth for the application configuration?  #no-scattered-country-configuration
const creditOnlyFinancialTechnique: FinancialTechniqueConfiguration[] = [
  {
    technique: "financialTechnique.credit",
  },
];

// @standard-link-country-brand
const ContriesConfigurationPC: CountryConfiguration[] = [Brand.AP, Brand.AC]
  .map((brand) => [
    {
      value: "FR",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.FR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: true,
    } satisfies CountryConfiguration,
    {
      value: "ES",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.ES,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "GB",
      brand,
      currency: "GBP",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: standardUnitDefinitionsUk,
      valueDisplay: CountriesMap.GB,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "BE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.BE,
      defaultClientType: "B2C",
      regionsB2C: regionsB2CBE,
      regionsB2B: regionsB2BBE,
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "DE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.DE,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "IT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.IT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "AT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.AT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PL",
      brand,
      currency: "PLN",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "NL",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.NL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "TR",
      brand,
      currency: "TL",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.TR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: [
        {
          technique: "financialTechnique.credit",
          default: true,
        },
      ],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
  ])
  .flat();

// @standard-link-country-brand
const ContriesConfigurationDS: CountryConfiguration[] = [Brand.DS]
  .map((brand) => [
    {
      value: "FR",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.FR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: true,
    } satisfies CountryConfiguration,
    {
      value: "ES",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.ES,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "GB",
      brand,
      currency: "GBP",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: standardUnitDefinitionsUk,
      valueDisplay: CountriesMap.GB,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "BE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.BE,
      defaultClientType: "B2C",
      regionsB2C: regionsB2CBE,
      regionsB2B: regionsB2BBE,
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "DE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.DE,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "IT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.IT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "AT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.AT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PL",
      brand,
      currency: "PLN",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "NL",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.NL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "TR",
      brand,
      currency: "TL",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.TR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: [
        {
          technique: "financialTechnique.credit",
          default: true,
        },
      ],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
  ])
  .flat();

// @standard-link-country-brand
const ContriesConfigurationALJE: CountryConfiguration[] = [Brand.JE, Brand.AR]
  .map((brand) => [
    {
      value: "FR",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.FR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "ES",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.ES,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      regionsB2B: RegionsSet.empty(),
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "GB",
      brand,
      currency: "GBP",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: standardUnitDefinitionsUk,
      valueDisplay: CountriesMap.GB,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "BE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.BE,
      defaultClientType: "B2C",
      regionsB2C: regionsB2CBE,
      regionsB2B: regionsB2BBE,
      financialTechniques: creditOnlyFinancialTechnique,
      vehicleGenresListElectric: ["VP"],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "DE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.DE,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      financialTechniques: creditOnlyFinancialTechnique,
      vehicleGenresListElectric: ["VP"],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "IT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.IT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      financialTechniques: creditOnlyFinancialTechnique,
      vehicleGenresListElectric: ["VP"],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "AT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.AT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      financialTechniques: creditOnlyFinancialTechnique,
      vehicleGenresListElectric: ["VP"],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PL",
      brand,
      currency: "PLN",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      financialTechniques: creditOnlyFinancialTechnique,
      vehicleGenresListElectric: ["VP"],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
  ])
  .flat();
// @standard-link-country-brand
const ContriesConfigurationFI: CountryConfiguration[] = [Brand.FT]
  .map((brand) => [
    {
      value: "FR",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.FR,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "ES",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.ES,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "GB",
      brand,
      currency: "GBP",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: standardUnitDefinitionsUk,
      valueDisplay: CountriesMap.GB,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "DE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.DE,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "IT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.IT,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "AT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.AT,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PL",
      brand,
      currency: "PLN",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PL,
      defaultClientType: "B2B",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
  ])
  .flat();

// @standard-link-country-brand
const ContriesConfigurationOV: CountryConfiguration[] = [Brand.OV]
  .map((brand) => [
    {
      value: "FR",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.FR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "ES",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.ES,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "GB",
      brand,
      currency: "GBP",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: standardUnitDefinitionsUk,
      valueDisplay: CountriesMap.GB,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "BE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.BE,
      defaultClientType: "B2C",
      regionsB2C: regionsB2CBE,
      regionsB2B: regionsB2BBE,
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "DE",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.DE,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "IT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.IT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "AT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.AT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PL",
      brand,
      currency: "PLN",
      currencyDisplay: "code",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "NL",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.NL,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: {
        electric: true,
        hybrid: false,
      },
    } satisfies CountryConfiguration,
    {
      value: "TR",
      brand,
      currency: "TRY",
      currencyDisplay: "symbol",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.TR,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: creditOnlyFinancialTechnique,
      B2CVOVN: false,
    } satisfies CountryConfiguration,
    {
      value: "PT",
      brand,
      currency: "EUR",
      currencyDisplay: "symbol-narrow",
      standardUnitDefinitions: { ...defaultStandardUnits },
      valueDisplay: CountriesMap.PT,
      defaultClientType: "B2C",
      regionsB2C: RegionsSet.empty(),
      regionsB2B: RegionsSet.empty(),
      vehicleGenresListElectric: ["VP", "VU"],
      financialTechniques: [
        {
          technique: "financialTechnique.credit",
          default: true,
        },
        {
          technique: "financialTechnique.lease",
        },
      ],
      B2CVOVN: false,
    } satisfies CountryConfiguration,
  ])
  .flat();

export const CountriesConfiguration: CountryConfiguration[] = [
  ...ContriesConfigurationPC,
  ...ContriesConfigurationDS,
  ...ContriesConfigurationOV,
  ...ContriesConfigurationALJE,
  ...ContriesConfigurationFI,
];
export function getCountryConfigurationListForBrand(
  countriesConfiguration: CountryConfiguration[],
  brand: Brand,
): CountryConfiguration[] {
  return countriesConfiguration.filter(
    ({ brand: countriesConfigurationBrand }) =>
      countriesConfigurationBrand === brand,
  );
}
export function getCountryConfiguration(
  countriesConfiguration: CountryConfiguration[],
  country: Country,
  brand: Brand,
): CountryConfiguration {
  return throwIfNullish(
    countriesConfiguration.find(
      ({ value, brand: countriesConfigurationBrand }) =>
        value === country && countriesConfigurationBrand === brand,
    ),
    {
      customError: `${
        BrandsFormatting[brand]
      } not available in ${uppercaseFirstLetter(CountriesMap[country])}`,
      logAdditionalInfo: `Add the configuration for ${country}, ${brand} in src/shared/countries.definitions.ts`,
    },
  );
}

export const DEFAULT_COUNTRY = Object.freeze({
  country: CountriesConfiguration[0].value,
  currency: CountriesConfiguration[0].currency,
  currencyDisplay: CountriesConfiguration[0].currencyDisplay,
  standardUnitDefinitions: CountriesConfiguration[0].standardUnitDefinitions,
});

// @standard-add-country
enum LanguagesEnum {
  "fr-FR" = "french",
  "es-ES" = "spanish",
  "en-GB" = "english",
  "fr-BE" = "french (belgium)",
  "de-DE" = "german",
  "it-IT" = "italian",
  "de-AT" = "german (austria)",
  "nl-BE" = "flemish",
  "pl-PL" = "polish",
  "nl-NL" = "dutch",
  "tr-TR" = "turkic",
  "pt-PT" = "portuguese",
}

const languagesTokensList: AutogenerateTupleFromUnion<LanguagesEnum> = [
  LanguagesEnum["fr-FR"],
  LanguagesEnum["es-ES"],
  LanguagesEnum["en-GB"],
  LanguagesEnum["fr-BE"],
  LanguagesEnum["de-DE"],
  LanguagesEnum["it-IT"],
  LanguagesEnum["de-AT"],
  LanguagesEnum["nl-BE"],
  LanguagesEnum["pl-PL"],
  LanguagesEnum["nl-NL"],
  LanguagesEnum["tr-TR"],
  LanguagesEnum["pt-PT"],
];
marker(languagesTokensList);

export type Language = keyof typeof LanguagesEnum;
export type TechnicalLanguage = "technical";

type LanguageListItem = ListItem<Language, (typeof LanguagesEnum)[Language]>;

export const LanguagesList: (
  | LanguageListItem
  | ListItem<TechnicalLanguage, "technical">
)[] = Object.keys(LanguagesEnum).map((key) => ({
  value: key as Language,
  valueDisplay: LanguagesEnum[key as Language],
}));
