import {
  UserInfoBrandInfo,
  UserParams,
} from "src/app/services/user-params.types";
import {
  Brand,
  BrandType,
  BrandsList,
  BrandsValuesList,
  brandsListFromBrandTypes,
} from "src/shared/brands.definitions";
import {
  CountriesConfiguration,
  CountriesMap,
  Country,
  CountryConfiguration,
  DEFAULT_COUNTRY,
  Language,
  TechnicalLanguage,
} from "src/shared/countries.definitions";
import {
  CountryToDefaultLanguageMap,
  LDAP_TO_COUNTRY_MAPPER,
  LanguageCountryCodeFromLdap,
  UserInfoOIDC,
  UserInfoSAML,
} from "src/shared/user-info";
import {
  parseValueAsArray,
  throwIfNullish,
  tryObjectValue,
} from "src/shared/utils";
import { gdprProtect } from "src/shared/utils.gdpr";
import {
  Failure,
  Success,
  Try,
  flatMapTry,
  getSuccessOrThrow,
  isSuccess,
} from "src/shared/utils.monad";

const ESA_LDAP_GROUP_TO_BRAND_TYPE = {
  "ESA.USER.ALL": BrandType.all,
  "ESA.USER.PCD": BrandType.pcd,
  "ESA.USER.OV": BrandType.ov,
  "ESA.USER.FCA": BrandType.fca,
};

export const DEFAULT_USER_PARAMS: UserParams = {
  uid: "NO_UID",
  ...DEFAULT_COUNTRY,
  brand: Brand.AP,
  brandsList: BrandsList,
  brandTypes: [BrandType.all],
  email: { value: "", gdprProtected: true },
  language: navigator.language as Language,
  languageForLocale: navigator.language as Language,
  givenName: { value: "", gdprProtected: true },
  surName: { value: "", gdprProtected: true },
  phone: { value: "", gdprProtected: true },
  rrdi7: "",
};

export class UserParamsMapper {
  static mapUserInfoOIDCToUserParams(userInfo: UserInfoOIDC): UserParams {
    const countryDetails = UserParamsMapper.inferCountryDetails(userInfo);
    const userParamsCountryProps = countryDetails
      ? {
          country: countryDetails.value,
          currency: countryDetails.currency,
          currencyDisplay: countryDetails.currencyDisplay,
          standardUnitDefinitions: countryDetails.standardUnitDefinitions,
        }
      : DEFAULT_COUNTRY;
    const language = UserParamsMapper.inferLanguage(countryDetails, userInfo);

    const phoneNumbers: string[] = parseValueAsArray(userInfo.phone);
    const phone = phoneNumbers.length > 0 ? phoneNumbers[0] : "";
    const brandInfo = UserParamsMapper.getUserParamsBrandInfoOIDC(userInfo);

    return {
      ...DEFAULT_USER_PARAMS,
      ...userParamsCountryProps,
      ...brandInfo,
      uid: userInfo.username,
      givenName: gdprProtect(userInfo.firstname),
      surName: gdprProtect(userInfo.lastname),
      rrdi7: userInfo.rrdi ?? "NORRDI7",
      language,
      languageForLocale: this.resolveLanguageForLocale(language),
      email: gdprProtect(userInfo.email ?? ""),
      phone: gdprProtect(phone),
    };
  }

  static mapUserInfoSAMLToUserParams(userInfo: UserInfoSAML): UserParams {
    const countryDetails = UserParamsMapper.inferCountryDetails({
      preferredlanguage: userInfo.preferredlanguage,
      country: userInfo.pays,
    });
    const userParamsCountryProps = countryDetails
      ? {
          country: countryDetails.value,
          currency: countryDetails.currency,
          currencyDisplay: countryDetails.currencyDisplay,
          standardUnitDefinitions: countryDetails.standardUnitDefinitions,
        }
      : DEFAULT_COUNTRY;
    const language = UserParamsMapper.inferLanguage(countryDetails, userInfo);

    const phoneNumbers: string[] = parseValueAsArray(userInfo.telephonenumber);
    const phone = phoneNumbers.length > 0 ? phoneNumbers[0] : "";
    const brandInfo = UserParamsMapper.getUserParamsBrandInfoSAML(userInfo);
    return {
      ...DEFAULT_USER_PARAMS,
      ...userParamsCountryProps,
      ...brandInfo,
      uid: userInfo.uid,
      givenName: gdprProtect(userInfo.givenname),
      surName: gdprProtect(userInfo.sn),
      rrdi7: userInfo.rrdi7 ?? "NORRDI7",
      language,
      languageForLocale: UserParamsMapper.resolveLanguageForLocale(language),
      email: gdprProtect(userInfo.email ?? userInfo.mail ?? ""),
      phone: gdprProtect(phone),
    };
  }

  private static inferLanguage(
    countryDetails: CountryConfiguration | null | undefined,
    localizationInfo: { preferredlanguage?: string | null | undefined },
  ): Language | TechnicalLanguage {
    if (localizationInfo.preferredlanguage === "technical") return "technical";

    const globalDefaultLanguage = "fr-FR";
    const countryToLanguageMap =
      CountryToDefaultLanguageMap[
        throwIfNullish(countryDetails?.value, {
          variableName: "countryDetails.value",
        })
      ];
    if (!countryDetails || !countryToLanguageMap) {
      console.error("Unknown country: ", countryDetails);
      return globalDefaultLanguage;
    }

    switch (countryToLanguageMap._type) {
      case "LdapCountryWithMultipleLanguages":
        return (
          countryToLanguageMap[
            localizationInfo.preferredlanguage as LanguageCountryCodeFromLdap
          ] ?? countryToLanguageMap.default
        );
      case "LdapCountryWithOneLanguage":
        return countryToLanguageMap.default;
      default:
        return globalDefaultLanguage;
    }
  }

  static resolveLanguageForLocale(
    language: Language | TechnicalLanguage,
  ): Language {
    return language === "technical" ? "fr-FR" : language;
  }

  private static inferCountryDetails(localizationInfo: {
    country?: string;
    preferredlanguage?: string | undefined | null;
  }): CountryConfiguration {
    const languageAsLdapCountryCode = localizationInfo.preferredlanguage;
    const countryFromLanguageLdap = (LDAP_TO_COUNTRY_MAPPER[
      languageAsLdapCountryCode as keyof typeof LDAP_TO_COUNTRY_MAPPER
    ] ??
      (CountriesMap[languageAsLdapCountryCode as Country]
        ? (languageAsLdapCountryCode as Country)
        : null) ??
      (languageAsLdapCountryCode === "technical"
        ? languageAsLdapCountryCode
        : null)) as Country | TechnicalLanguage | null;

    return throwIfNullish(
      CountriesConfiguration.find(
        ({ value }) =>
          value ===
          (localizationInfo.country ??
            countryFromLanguageLdap ??
            DEFAULT_COUNTRY.country),
      ),
      {
        variableName: "countryDetails",
      },
    );
  }

  private static getUserParamsBrandInfoSAML(
    userInfo: UserInfoSAML,
  ): UserInfoBrandInfo {
    const brandTypes: BrandType[] = getSuccessOrThrow(
      UserParamsMapper.resolveBrandTypes(userInfo.groups),
    );
    const brandsList = brandsListFromBrandTypes(brandTypes);
    if (brandsList.length === 0) {
      console.error(`No brand found user ${userInfo.uid}`);
      throw new Error(
        "You do not have the necessary roles to access TCO. Please ask an administrator.",
      );
    }
    const defaultBrand: Brand = brandsList[0].value;
    const brand: Brand =
      userInfo.brand && BrandsValuesList.includes(userInfo.brand as Brand)
        ? (userInfo.brand as Brand)
        : defaultBrand;

    return {
      brand,
      brandTypes,
      brandsList,
    };
  }
  private static getUserParamsBrandInfoOIDC(
    userInfo: UserInfoOIDC,
  ): UserInfoBrandInfo {
    const brandTypes: BrandType[] = getSuccessOrThrow(
      UserParamsMapper.resolveBrandTypes(userInfo.roles),
    );
    const brandsList = brandsListFromBrandTypes(brandTypes);
    if (brandsList.length === 0) {
      console.error(`No brand found user ${userInfo.username}`);
      throw new Error(
        "You do not have the necessary roles to access TCO. Please ask an administrator.",
      );
    }
    const defaultBrand: Brand = brandsList[0].value;
    const brand: Brand =
      userInfo.brand && BrandsValuesList.includes(userInfo.brand as Brand)
        ? (userInfo.brand as Brand)
        : defaultBrand;

    return {
      brand,
      brandTypes,
      brandsList,
    };
  }

  private static resolveBrandTypes(
    roles: string | string[] | undefined,
  ): Try<BrandType[]> {
    const errorMessage =
      "You do not have the necessary roles to access TCO. Please ask an administrator.";
    if (!roles) return Failure(errorMessage);
    if (typeof roles === "string") {
      return flatMapTry(
        tryObjectValue(
          ESA_LDAP_GROUP_TO_BRAND_TYPE,
          roles,
          `Role ${roles} does not give access to TCO. Please ask an administrator.`,
        ),
        (brandType) => [brandType],
      );
    }

    const brandTypes = roles
      .map((role) => tryObjectValue(ESA_LDAP_GROUP_TO_BRAND_TYPE, role))
      .filter(isSuccess)
      .map((success) => success.value);

    if (brandTypes.length === 0) return Failure(errorMessage);

    return Success(brandTypes);
  }
}
