<mat-expansion-panel
  class="feature-flag-panel"
  hideToggle
>
  <mat-expansion-panel-header></mat-expansion-panel-header>
  <mat-slide-toggle
    *ngFor="
      let featureFlag of featureFlagService.featureFlags$ | async | keyvalue
    "
    name="{{ featureFlag.key }}"
    [checked]="featureFlag.value.enabled"
    (change)="featureFlagService.toggle(featureFlag.key)"
    ngDefaultControl
    >{{ featureFlag.value.displayName }}
  </mat-slide-toggle>
</mat-expansion-panel>
