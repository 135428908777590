/**
 * Configure the exported default telemetry instance in the root file of your application
 *
 * ```typescript
 * # main.ts
 *
 * telemetry.setLogger({...})
 * telemetry.setMetricsLogger((...) => {...})
 * ```
 */

import { ONE_THOUSAND_MS } from "src/shared/magic-numbers";

type LoggerMethod = (message: string) => void;
// eslint-disable-next-line no-empty-function
const emptyLoggerMethod = () => {};

// eslint-disable-next-line no-empty-function
const emptyMetricsLogger = () => {};

type MetricsLogger = (
  eventName: string,
  metrics: Record<string, number | Date>,
) => void;

type Logger = {
  info: LoggerMethod;
  warn: LoggerMethod;
  error: LoggerMethod;
};

export class Telemetry {
  private _logger: Logger = {
    info: emptyLoggerMethod,
    warn: emptyLoggerMethod,
    error: emptyLoggerMethod,
  };
  private _metricsLogger: MetricsLogger = emptyMetricsLogger;
  private events: Record<string, { startDate: Date }> = {};

  // eslint-disable-next-line accessor-pairs
  setLogger(_logger: Logger) {
    this._logger = _logger;
  }

  // eslint-disable-next-line accessor-pairs
  setMetricsLogger(_metricsLogger: MetricsLogger) {
    this._metricsLogger = _metricsLogger;
  }

  start(eventName: string) {
    this.events[eventName] = { startDate: new Date() };
    this._logger.info(`${eventName}: started`);
  }

  stop(eventName: string) {
    if (!(eventName in this.events))
      this._logger.error(`Event ${eventName} stopped but not started`);

    const startDate = this.events[eventName].startDate;
    const totalTimeSpendMs = Date.now() - startDate.getTime();

    this._logger.info(
      `${eventName}: stopped. Elapsed time: ${(
        totalTimeSpendMs / ONE_THOUSAND_MS
      ).toFixed(2)} seconds`,
    );
    this._metricsLogger(eventName, { totalTimeSpendMs, startDate });
  }
}

export default new Telemetry();
