import { parse } from "papaparse";
import { Matrix, MatrixRow } from "src/shared/common-types";
import { rrdiMatrix } from "src/shared/matrices/matrices";

enum RRDIRepositoryHeaders {
  RRDI7 = "Code RRDI",
  DEALERSHIP_NAME = "Nom Apporteur",
}

export class RRDIRepository {
  static RRDIToDealershipName: Matrix = parse<MatrixRow>(rrdiMatrix, {
    dynamicTyping: false,
    header: true,
    skipEmptyLines: true,
  }).data;

  static getDealershipName(rrdi7: string): string | undefined {
    const dealershipLine = RRDIRepository.RRDIToDealershipName.find(
      ({ [RRDIRepositoryHeaders.RRDI7]: matrixRrdi7 }) => matrixRrdi7 === rrdi7,
    );

    if (dealershipLine)
      return dealershipLine[RRDIRepositoryHeaders.DEALERSHIP_NAME] as string;
  }
}
