import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeNl from "@angular/common/locales/nl";
import localePl from "@angular/common/locales/pl";
import localePtPt from "@angular/common/locales/pt-PT";
import localeTr from "@angular/common/locales/tr";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TraceService } from "@sentry/angular-ivy";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { AuthConfig, OAuthModule } from "angular-oauth2-oidc";
import { NgxTranslateCutModule } from "ngx-translate-cut";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { authAppInitializerFactory } from "src/app/auth-app-initializer.factory";
import { GlobalErrorHandlerWithSentry } from "src/app/global-error-handler.service";
import { MaterialModule } from "src/app/material/material.module";
import { authCodeFlowConfig } from "src/app/services/auth.config";
import { AuthService } from "src/app/services/auth.service";
import { CustomTranslateLoader } from "src/app/services/lang-switcher.service";
import { SentryService } from "src/app/services/sentry.service";
import { UIModule } from "src/app/ui/ui.module";
import { isSome } from "src/shared/utils.monad";

registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localeTr);
registerLocaleData(localePtPt);

const GTM_ID = "GTM-NTP5NTG";
const hostname = window.location.hostname;

/*
 * Get the mapping from
 * [Environments](https://tagmanager.google.com/#/admin/accounts/
 * 6002247809/containers/33092361/environments?containerDraftId=2)
 * and actions -> obtain code sample
 */
const hostnameToGtmMapping: Record<string, Record<string, string>> = {
  "localhost": {
    GTM_AUTH: "recIlmWdJDdOBRBDvICc9w",
    GTM_ENV: "env-6",
  },
  "tco-psabank.groupe-psa.com": {
    GTM_AUTH: "6rn7RCHfDEzW46ebWizczA",
    GTM_ENV: "env-1",
  },
};

const { GTM_AUTH, GTM_ENV } = hostnameToGtmMapping[hostname] || {};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    UIModule,
    TranslateModule.forRoot({
      defaultLanguage: "  ",
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
    NgxTranslateCutModule,
    GoogleTagManagerModule.forRoot({
      id: GTM_ID,
    }),
    OAuthModule.forRoot(),
  ],
  providers: [
    ...(isSome(authCodeFlowConfig)
      ? [
          {
            provide: APP_INITIALIZER,
            useFactory: authAppInitializerFactory,
            deps: [AuthService],
            multi: true,
          },
          { provide: AuthConfig, useValue: authCodeFlowConfig.value },
        ]
      : []),
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: "googleTagManagerId", useValue: GTM_ID },
    { provide: "googleTagManagerAuth", useValue: GTM_AUTH },
    { provide: "googleTagManagerPreview", useValue: GTM_ENV },
    {
      // Processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerWithSentry,
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [TraceService, SentryService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppModule {}
