/* eslint-disable max-lines */
import { HUNDRED_PERCENT } from "src/shared/magic-numbers";
import {
  Failure,
  Success,
  Try,
  getSuccessOrElse,
} from "src/shared/utils.monad";
import { SafeParseReturnType } from "zod";

export function cartesianProduct<T1>(arr1: readonly T1[]): [T1][];
export function cartesianProduct<T1, T2>(
  arr1: readonly T1[],
  arr2: readonly T2[],
): [T1, T2][];
export function cartesianProduct<T1, T2, T3>(
  arr1: readonly T1[],
  arr2: readonly T2[],
  arr3: readonly T3[],
): [T1, T2, T3][];
export function cartesianProduct<T1, T2, T3, T4>(
  arr1: readonly T1[],
  arr2: readonly T2[],
  arr3: readonly T3[],
  arr4: readonly T4[],
): [T1, T2, T3, T4][];
export function cartesianProduct<T1, T2, T3, T4, T5>(
  arr1: readonly T1[],
  arr2: readonly T2[],
  arr3: readonly T3[],
  arr4: readonly T4[],
  arr5: readonly T5[],
): [T1, T2, T3, T4, T5][];
export function cartesianProduct(...arr: (readonly unknown[])[]): unknown[][] {
  return arr.reduce<unknown[][]>(
    (a: unknown[][], b: readonly unknown[]) =>
      a
        .map((x: unknown[]) => b.map((y: unknown) => x.concat([y])))
        .reduce((c: unknown[][], d: unknown[]) => c.concat(d), []),
    [[]],
  );
}

type NonNullish<T> = T extends null | undefined ? never : T;

export function isNullish<T>(
  value: NonNullish<T> | undefined | null,
): value is undefined | null {
  return value === undefined || value === null;
}

export function throwIfNullish<T>(
  value: NonNullish<T> | undefined | null,
  {
    variableName,
    customError,
    logAdditionalInfo,
  }:
    | { variableName: string; customError?: string; logAdditionalInfo?: string }
    | {
        variableName?: string;
        customError: string;
        logAdditionalInfo?: string;
      },
): NonNullish<T> {
  if (isNullish(value)) {
    const message = customError
      ? customError
      : variableName
        ? `Value ${variableName} is nullish`
        : "Value is nullish, but no variable name was provided";
    console.error(
      message + (logAdditionalInfo ? `: ${logAdditionalInfo}` : ""),
    );
    throw new Error(message);
  }
  return value;
}

export function sortAndStartWith<T>(arr: T[], ...startWith: T[]): T[] {
  return arr
    .filter((value) => startWith.includes(value))
    .concat(arr.filter((value) => !startWith.includes(value)).sort());
}

type Primitive = number | string | boolean | Date | null;

type UnionToIntersection<U> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (U extends any ? (k: U) => void : never) extends (k: infer I) => void
    ? I
    : never;

type Flattened<T, Sep extends string> = T extends
  | Primitive
  | unknown[]
  | undefined
  | Record<string, Primitive | undefined | unknown[]>
  ? T
  : T extends Record<string, unknown>
    ? UnionToIntersection<
        {
          [K in keyof T & string]: Flattened<T[K], Sep> extends infer Flat
            ? Flat extends Primitive | undefined | unknown[]
              ? Flat extends Primitive | undefined
                ? { [P in K]?: T[P] }
                : { [P in K]: T[P] }
              : Flat extends Record<string, unknown>
                ? {
                    [KFlat in keyof Flat &
                      string as `${K}${Sep}${KFlat}`]: Flat[KFlat];
                  }
                : Flat extends unknown
                  ? { [KFlat in K]: Flat }
                  : never
            : never;
        }[keyof T & string]
      > extends infer O
      ? { [K in keyof O]: O[K] }
      : never
    : T extends unknown
      ? T
      : never;

export function flattenObj<
  O extends Record<string, unknown>,
  // eslint-disable-next-line no-use-before-define
  Out extends Flattened<O, Sep>,
  Sep extends string = "_",
>(
  obj: O,
  sep: Sep = "_" as Sep,
  parentPropName: string | null = null,
  res: Partial<Out> = {},
): Out {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = parentPropName ? `${parentPropName}${sep}${key}` : key;
      if (typeof obj[key] === "object")
        flattenObj(obj[key] as Record<string, unknown>, sep, propName, res);
      else res[propName as keyof Out] = obj[key] as unknown as Out[keyof Out];
    }
  }

  return res as Out;
}

export function shallowEqual<K extends string | number | symbol>(
  object1: Record<K, unknown>,
  object2: Record<K, unknown>,
) {
  if (Object.keys(object1).length !== Object.keys(object2).length) return false;

  for (const key in object1) if (object1[key] !== object2[key]) return false;

  return true;
}

function isObjectWithToString(value: unknown): boolean {
  return (
    typeof value === "object" &&
    value !== null &&
    value.toString !== undefined &&
    typeof value.toString === "function"
  );
}

export function hasToString(value: unknown): value is object | string | number {
  return (
    isObjectWithToString(value) ||
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "bigint"
  );
}

export function splitVatPrice(price: number, vatRate: number) {
  const priceHt = price / (1 + vatRate / HUNDRED_PERCENT);
  const vat = (priceHt * vatRate) / HUNDRED_PERCENT;
  return {
    priceHt,
    vat,
  };
}

type ValueOfRecord<R extends Record<string, unknown>> = R extends Record<
  string,
  infer T
>
  ? T
  : never;

export function getAttrCaseInsensitive<R extends Record<string, unknown>>(
  record: R,
  textFieldName: string,
): ValueOfRecord<R> {
  const exactValue = record[textFieldName];
  if (exactValue !== undefined) return exactValue as ValueOfRecord<R>;

  const lowerCaseTextFieldName = textFieldName.toLowerCase();
  const lowerCaseExportedValues = Object.fromEntries(
    Object.entries(record).map(([key, value]) => [key.toLowerCase(), value]),
  );
  return lowerCaseExportedValues[lowerCaseTextFieldName] as ValueOfRecord<R>;
}

export function sanitizeForFilename(stringValue: string) {
  // List from https://stackoverflow.com/a/26900132 as safe strings for all systems
  return stringValue.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9-]/giu, "_");
}

function isLargeObject(value: object) {
  const TOO_MANY_KEYS = 100;
  const TOO_LARGE_KEYS = 30;
  const tooManyKeys = Object.keys(value).length > TOO_MANY_KEYS;
  const tooLargeKeys = Object.keys(value).some(
    (key) => key.length > TOO_LARGE_KEYS,
  );
  return tooManyKeys || tooLargeKeys;
}

function limitStringSize(stringValue: string) {
  const TOO_LARGE_STRING = 256;
  if (stringValue.length > TOO_LARGE_STRING)
    return `${stringValue.substring(0, TOO_LARGE_STRING)}...`;
  return stringValue;
}
function escapeJsonProtectedChars(stringValue: string) {
  return stringValue.replace(/\\/gu, "\\\\").replace(/"/gu, '\\"');
}

function unknownObjectToString(unknownObject: object): string {
  if (Array.isArray(unknownObject)) {
    if (isLargeObject(unknownObject))
      return `Large array with ${unknownObject.length} elements`;
    else {
      return `[${unknownObject
        .map((value) => {
          if (typeof value === "string")
            return `"${limitStringSize(escapeJsonProtectedChars(value))}"`;
          return `${
            // eslint-disable-next-line no-use-before-define
            unknownToString(value)
          }`;
        })
        .join(",")}]`;
    }
  } else {
    if (isLargeObject(unknownObject))
      return `Large object with keys ${Object.keys(unknownObject).join(", ")}`;

    return `{${Object.entries(unknownObject)
      .map(([key, value]) => {
        if (typeof value === "string") {
          return `"${escapeJsonProtectedChars(key)}":"${limitStringSize(
            escapeJsonProtectedChars(value),
          )}"`;
        }
        if (typeof value === "object" && value !== null) {
          return `"${escapeJsonProtectedChars(key)}":${unknownObjectToString(
            value as object,
          )}`;
        }
        return `"${escapeJsonProtectedChars(key)}":${limitStringSize(
          // eslint-disable-next-line no-use-before-define
          unknownToString(value),
        )}`;
      })
      .join(",")}}`;
  }
}

export function unknownToString(unknownValue: unknown, defaultValue = "") {
  if (
    unknownValue === undefined ||
    unknownValue === null ||
    typeof unknownValue === "boolean"
  )
    return `${unknownValue}`;
  if (typeof unknownValue === "object") {
    if (unknownValue instanceof Error) {
      const { message, cause } = unknownValue;
      return JSON.stringify({ message, cause });
    } else return unknownObjectToString(unknownValue);
  }
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return hasToString(unknownValue) ? unknownValue.toString() : defaultValue;
}

export function enforceString(value: unknown): string {
  if (typeof value !== "string") {
    throw new Error(
      `Value ${unknownToString(value, "(not displayable)")} is not a string`,
    );
  }

  return value;
}

export function enforceNumber(value: unknown): number {
  if (typeof value !== "number") {
    throw new Error(
      `Value ${unknownToString(value, "(not displayable)")} is not a number`,
    );
  }

  return value;
}

export function enforceBoolean(value: unknown): boolean {
  if (typeof value !== "boolean") {
    throw new Error(
      `Value ${unknownToString(value, "(not displayable)")} is not a boolean`,
    );
  }

  return value;
}

export function exhaustive(value: never): never {
  throw new Error(
    `Non exhaustive: ${unknownToString(value, "(not displayable)")}`,
  );
}

export function defaultZodResult<T>(
  zreturn: SafeParseReturnType<T, T>,
  defaultValue: T,
) {
  return zreturn.success ? zreturn.data : defaultValue;
}

type InvertedMap<T extends Record<string, string>> = {
  [Property in keyof T as T[Property]]: Property;
};
export function invertMap<T extends Record<string, string>>(
  obj: T,
): InvertedMap<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [value, key]),
  ) as InvertedMap<T>;
}

export function parseValueAsArray<T extends string | number | Date>(
  value: T[] | T | undefined,
) {
  return value === undefined ? [] : Array.isArray(value) ? value : [value];
}

export function generateNumberArray(max: number, min = 0, step = 1): number[] {
  return [...Array<never>(Math.floor((max - min) / step))].map(
    (_, index) => index * step + min,
  );
}

type PrimitiveTypes =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "array"
  | "function";
type StringTypeToType<T extends PrimitiveTypes> = T extends "string"
  ? string
  : T extends "number"
    ? number
    : T extends "boolean"
      ? boolean
      : T extends "function"
        ? () => unknown
        : T extends "object"
          ? object
          : T extends "array"
            ? unknown[]
            : never;

function resolvePrimitiveType(unknownValue: unknown): PrimitiveTypes {
  if (typeof unknownValue === "string") return "string";
  if (typeof unknownValue === "number") return "number";
  if (typeof unknownValue === "boolean") return "boolean";
  if (typeof unknownValue === "function") return "function";
  if (typeof unknownValue === "object") {
    if (Array.isArray(unknownValue)) return "array";
    return "object";
  }
  return "object";
}

function castToType<T extends PrimitiveTypes, U extends StringTypeToType<T>>(
  unknownValue: unknown,
  attributeType: T,
): Try<U> {
  if (
    attributeType === "string" &&
    resolvePrimitiveType(unknownValue) === "number"
  )
    return Success(String(unknownValue)) as Try<U>;
  if (attributeType === "number") {
    const numberValue = Number(unknownValue);
    if (isNaN(numberValue))
      return Failure(`Value ${unknownToString(unknownValue)} is not a number`);
    return Success(numberValue) as Try<U>;
  }

  return Failure(
    `Attribute type ${attributeType} is not supported for casting`,
  );
}

export function readAttributeFromUnknown<AT extends PrimitiveTypes>(
  unknownValue: unknown,
  attributeName: string,
  attributeType: AT,
  defaultValue: StringTypeToType<AT> | undefined = undefined,
  { castToAttributeType } = { castToAttributeType: false },
): StringTypeToType<AT> | undefined {
  if (typeof unknownValue !== "object" || unknownValue === null)
    return defaultValue;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = unknownValue[attributeName as keyof typeof unknownValue];

  if (value === undefined) return defaultValue;

  if (resolvePrimitiveType(value) !== attributeType) {
    if (castToAttributeType) {
      console.warn(
        `Type mismatch between attribute ${attributeName} and expected type ${attributeType}. Attempting casting to ${attributeType}`,
      );
      return getSuccessOrElse(castToType(value, attributeType), defaultValue);
    }

    throw new Error(
      `Type mismatch between attribute ${attributeName} and expected type ${attributeType}`,
    );
  }

  return value as StringTypeToType<AT>;
}

export function trimStringValues<T extends Record<string, unknown>>(obj: T): T {
  const trimmedObj: T = { ...obj };
  for (const key in trimmedObj) {
    if (typeof trimmedObj[key] === "string") {
      trimmedObj[key] = (trimmedObj[key] as string).trim() as T[Extract<
        keyof T,
        string
      >];
    }
  }
  return trimmedObj;
}

export function eqSet<T>(xs: Set<T>, ys: Set<T>) {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
}

export function assert(
  condition: boolean,
  message?: string,
  error: Error = new Error(message),
): asserts condition {
  if (!condition) throw error;
}
export function assert2(
  condition: boolean,
  message?: string,
  error: Error = new Error(message),
): asserts condition {
  if (!condition) throw error;
}
export function assert3(
  condition: boolean,
  message?: string,
  error: Error = new Error(message),
): asserts condition {
  if (!condition) throw error;
}
export function assert4(
  condition: boolean,
  message?: string,
  error: Error = new Error(message),
): asserts condition {
  if (!condition) throw error;
}
// eslint-disable-next-line no-empty-function, @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
export function empty(..._: unknown[]) {}

export function pick<T extends Record<string, unknown>>(
  obj: T,
  keys: (keyof T)[],
) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key as keyof T)),
  ) as Pick<T, (typeof keys)[number]>;
}

export function getObjectKeys<T extends object>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

export function tryObjectValue<T extends object, K extends PropertyKey>(
  obj: T,
  key: K,
  message: string = `Cannot get key for object ${unknownObjectToString(obj)}`,
): Try<T[keyof T]> {
  if (!obj.hasOwnProperty(key)) return Failure(message);

  return Success(obj[key as unknown as keyof T]);
}

export function isStringCastableToNumber(str: string) {
  const num = Number(str);
  return isFinite(num);
}
