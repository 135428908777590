import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import {
  BrowserTracing,
  getCurrentHub,
  init,
  instrumentAngularRouting,
  setTag,
} from "@sentry/angular-ivy";
import { AppModule } from "src/app/app.module";
import { retryImport } from "src/app/utils";
import { environment } from "src/environments/environment";
import { resolveServerEnvironmentName } from "src/environments/environment.browser";
import { throwIfNullish } from "src/shared/utils";
import telemetry from "src/shared/utils.telemetry";
import versionControl from "src/shared/version.json";

const hasSentry = environment.sentryDSN !== "";

if (hasSentry) {
  const environmentName = resolveServerEnvironmentName(
    window.location.hostname,
  ).toLowerCase();

  init({
    dsn: environment.sentryDSN,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [/^\//u],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    replaysSessionSampleRate: 0.03, // As the limit is 50 replays / month
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    environment: environmentName,
    release: `app-tco@${versionControl.version}`,
  });

  setTag("catalogFile", versionControl.catalogFile);
}

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => console.error(error));

// eslint-disable-next-line no-console
console.log(
  `TCO version ${versionControl.version}, catalog ${versionControl.catalogFile}`,
);

if (hasSentry) {
  (async () => {
    const { Replay } = await retryImport(() => import("@sentry/browser"));
    const hub = getCurrentHub();
    const client = throwIfNullish(hub.getClient(), {
      variableName: "getCurrentHub.getClient()",
    });
    if (client.addIntegration) {
      client.addIntegration(
        new Replay({
          maskAllInputs: false,
          maskAllText: false,
          mask: [".gdpr-private"],
        }),
      );
    }
  })();
}

if (environment.telemetry) telemetry.setLogger(console);
