import { type Environment } from "src/environments/environment.type";

export const environment: Environment = {
  sentryDSN:
    "https://901fed89e63e4026933335acf78ec84f@o4504537350471680.ingest.sentry.io/4504537351651328",
  production: true,
  useDummyUser: false,
  enableBetaFeatures: false,
  disableAnimations: false,
  disableGTM: false,
  adminList: [
    "E594904",
    "U420690",
    "U275932",
    "E551206",
    "U466908", // Louis
    "U195732", // Clémence
  ],
  telemetry: false,
};
