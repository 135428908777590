import { unknownToString } from "src/shared/utils";

type BoxedError<Code extends string = string> =
  | {
      type: "recoverable";
      code: Code;
      reason: string | Error;
    }
  | {
      type: "fatal";
      reason: unknown;
    };

export function Success<T>(value: T) {
  return {
    __meta: "success",
    type: "ok",
    value,
  } as const;
}

export function Failure<Code extends string>(
  error: BoxedError<Code> | Error | string,
) {
  if (error instanceof Error || typeof error === "string") {
    const reboxedError: BoxedError = {
      type: "fatal",
      reason: error,
    };
    return {
      __meta: "failure",
      type: "error",
      error: reboxedError,
    } as const;
  }

  return {
    __meta: "failure",
    type: "error",
    error,
  } as const;
}

export function Try<T>(fn: () => T) {
  try {
    return Success(fn());
  } catch (error) {
    const boxedError = {
      type: "fatal" as const,
      reason: error,
    };
    return Failure(boxedError);
  }
}

export type Success<T> = ReturnType<typeof Success<T>>;
export type Failure<Code extends string = string> = ReturnType<
  typeof Failure<Code>
>;
export type Try<T, Code extends string = string> = Success<T> | Failure<Code>;

export function isSuccess<T>(tryValue: Try<T>): tryValue is Success<T> {
  return tryValue.__meta === "success";
}
export function isFailure(tryValue: Try<unknown>): tryValue is Failure {
  return tryValue.__meta === "failure";
}

export const flatMapTry = <RS, T>(
  tryValue: Try<T>,
  mapSuccess: (successValue: T) => RS,
): Try<RS> => {
  switch (tryValue.__meta) {
    case "success":
      return Success(mapSuccess(tryValue.value));
    case "failure":
      return tryValue;
    default:
      throw new Error("Invalid Try value");
  }
};

export const matchTry = <RS, RF, T>(
  tryValue: Try<T>,
  {
    success = (_) => _ as unknown as RS,
    failure = (_) => _ as unknown as RF,
  }: {
    success?: (value: T) => RS;
    failure?: (error: BoxedError) => RF;
  },
) => {
  switch (tryValue.__meta) {
    case "success":
      return success(tryValue.value);
    case "failure":
      return failure(tryValue.error);
    default:
      throw new Error("Invalid Try value");
  }
};

export function getSuccessOrElse<T>(tryValue: Try<T>, defaultValue: T) {
  if (tryValue.__meta === "success") return tryValue.value;

  console.warn(tryValue.error.reason);

  return defaultValue;
}
export function getSuccessOrThrow<T>(tryValue: Try<T>, message?: string) {
  if (tryValue.__meta === "success") return tryValue.value;

  throw new Error(
    message
      ? message
      : `Try was Failure with error ${unknownToString(tryValue.error.reason)}`,
  );
}

export function tryFlatMap<T, U>(
  tryValue: Try<T>,
  fn: (value: T) => Try<U>,
): Try<U> {
  return matchTry(tryValue, {
    success: fn,
    failure: (error) => Failure(error),
  });
}

export function None() {
  return {
    __meta: "none",
  } as const;
}

export function Some<T>(value: T) {
  return {
    __meta: "some",
    value,
  } as const;
}

export type None = ReturnType<typeof None>;
export type Some<T> = ReturnType<typeof Some<T>>;
export type Option<T> = Some<T> | None;

export function isSome<T>(option: Option<T>): option is Some<T> {
  return option.__meta === "some";
}
export function isNone(option: Option<unknown>): option is None {
  return option.__meta === "none";
}
export function optionFlatMap<T, U>(
  option: Option<T>,
  fn: (value: T) => U,
): Option<U> {
  return isSome(option) ? Some(fn(option.value)) : None();
}
