<div
  class="loading-screen-wrapper"
  *ngIf="theme === null"
>
  <div class="loading-screen-icon">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div
  class="app default-theme"
  *ngIf="theme !== null"
>
  <mat-toolbar
    class="main-toolbar"
    [color]="theme.toolbarPrimary ? 'primary' : 'default'"
  >
    <a
      [routerLink]="['/']"
      class="logo-link"
    >
      <img
        src="assets/main_logos/{{ theme.logoFinance | translate }}"
        class="logo"
        alt="{{ theme.logoFinanceDescription }}"
      />
    </a>

    <span class="space-between"></span>
    <img
      src="assets/brand_logos/{{ theme.logoBrand | translate }}"
      class="logo logo-brand"
      alt=" {{ theme.logoBrandDescription }}"
    />
    <button
      data-cy="account-btn"
      class="account hide-on-print"
      [class.gdpr-private]="userParamsService.params.givenName.gdprProtected"
      mat-button
      (click)="drawer.toggle()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="User account"
        >account_circle</mat-icon
      >
      {{ userParamsService.params.givenName.value | uppercase }}
    </button>
    <button
      data-cy="menu-btn"
      class="hide-on-print"
      mat-button
      tabindex="0"
      (click)="drawer.toggle()"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="Menu button"
        >menu</mat-icon
      >
    </button>
  </mat-toolbar>
  <mat-drawer-container
    hasBackdrop="true"
    id="main-content"
  >
    <mat-drawer
      position="end"
      #drawer
    >
      <div class="drawer-content">
        <button
          data-cy="account-btn"
          class="account hide-on-print"
          [class.gdpr-private]="
            userParamsService.params.givenName.gdprProtected
          "
          mat-button
          disabled
        >
          <mat-icon
            aria-hidden="false"
            aria-label="User account"
            >account_circle</mat-icon
          >
          {{ userParamsService.params.givenName.value | titlecase }}
        </button>
        <mat-form-field color="accent">
          <mat-label translate>brand</mat-label>
          <select
            data-cy="brand-select"
            matNativeControl
            [(ngModel)]="userParamsService.params.brand"
            (change)="userParamsService.fireChange()"
          >
            <option
              *ngFor="let listItem of userParamsService.params.brandsList"
              [ngValue]="listItem.value"
              [attr.data-cy]="listItem.value"
            >
              {{ listItem.valueDisplay | translate }}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label translate>country</mat-label>
          <select
            data-cy="country-select"
            matNativeControl
            [(ngModel)]="userParamsService.params.country"
            (change)="userParamsService.fireChange()"
          >
            <option
              *ngFor="let listItem of userParamsService.countryList"
              [ngValue]="listItem.value"
              [attr.data-cy]="listItem.value"
            >
              {{ listItem.valueDisplay | translate }}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label translate>language</mat-label>
          <select
            data-cy="language-select"
            matNativeControl
            [(ngModel)]="userParamsService.params.language"
            (change)="userParamsService.fireChange()"
          >
            <option
              *ngFor="let listItem of userParamsService.languageList"
              [ngValue]="listItem.value"
              [attr.data-cy]="listItem.value"
            >
              {{ listItem.valueDisplay | translate }}
            </option>
          </select>
        </mat-form-field>

        <a
          *ngIf="samlMethod === 'saml-link'"
          mat-raised-button
          color="warn"
          href="/saml/logout"
          >{{ "logout" | translate }}</a
        >
        <!-- Règle désactivée -->
        <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
        <a
          *ngIf="samlMethod === 'oauthService'"
          mat-raised-button
          color="warn"
          (click)="authService.logout()"
          tabIndex="0"
          (keyup.enter)="authService.logout()"
          >{{ "logout" | translate }}</a
        >
        <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
        <app-feature-flag></app-feature-flag>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <span class="space-between"></span>

      <div class="app-content">
        <div class="vertically-centered-content">
          <router-outlet></router-outlet>
        </div>
      </div>

      <span class="space-between"></span>

      <mat-toolbar
        color="warn"
        class="footer"
      >
        <p>
          {{ theme.legalDisclaimer | translate }}
        </p>
      </mat-toolbar>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
