import { NgModule, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CanActivateFn,
  PreloadAllModules,
  RouterModule,
  Routes,
} from "@angular/router";
import { map } from "rxjs";
import { canActivateAuthAndForceLogin } from "src/app/services/auth.guard.service";
import { UserParamsService } from "src/app/services/user-params.service";
import { UserParams } from "src/app/services/user-params.types";
import { retryImport } from "src/app/utils";
import { environment } from "src/environments/environment";
import localEnv from "src/environments/localenv";

const canActivateAdmin: CanActivateFn = () => {
  if (localEnv.debugLogs)
    // eslint-disable-next-line no-console
    console.log("Running canActivateAuthAndForceLogin");

  const snakBar = inject(MatSnackBar);
  const userParamsService = inject(UserParamsService);
  return userParamsService.onParamsChange$.pipe(
    map((params: UserParams) => {
      const canActivate = environment.adminList.includes(params.uid);
      if (!canActivate)
        snakBar.open("You are not authorized to access this page", "Close");
      return canActivate;
    }),
  );
};

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      retryImport(() =>
        import("./modules/home/home.module").then((m) => m.HomeModule),
      ),
    canActivate: [canActivateAuthAndForceLogin],
  },
  {
    path: "login",
    redirectTo: "",
  },
  {
    path: "tco",
    loadChildren: () =>
      retryImport(() =>
        import("./modules/tco/tco.module").then((m) => m.TcoModule),
      ),
    canActivate: [canActivateAuthAndForceLogin],
  },
  {
    path: "admin",
    loadChildren: () =>
      retryImport(() =>
        import("./modules/admin/admin.module").then((m) => m.AdminModule),
      ),
    canActivate: [canActivateAuthAndForceLogin, canActivateAdmin],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes.concat([
        {
          path: "**",
          loadChildren: () =>
            retryImport(() =>
              import("./modules/home/home.module").then((m) => m.HomeModule),
            ),
        },
      ]),
      {
        scrollPositionRestoration: "top",
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppRoutingModule {}
