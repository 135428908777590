import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FeatureFlagService } from "src/app/services/feature-flag.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-feature-flag",
  templateUrl: "./feature-flag.component.html",
  styleUrls: ["./feature-flag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagComponent {
  isDev = !environment.production;
  constructor(public featureFlagService: FeatureFlagService) {}
}
