import { AuthConfig } from "angular-oauth2-oidc";
import {
  BrowserEnvironments,
  resolveServerEnvironmentName,
} from "src/environments/environment.browser";
import localEnv from "src/environments/localenv";
import { None, Option, Some, optionFlatMap } from "src/shared/utils.monad";

/* Configuration */

// Do not use directly, use CLIENT_ID_FOR_ENVIRONMENT instead
const CLIENT_ID_PREPROD = "XARXQQNGIXQGMFWCWFIUWOVFETUHGSOT";
const CLIENT_ID_PROD = "XAPRQQNGIXQGMFWCWFIUWOVFETUHGSOT";

const CLIENT_ID_FOR_ENVIRONMENT: Record<BrowserEnvironments, Option<string>> = {
  [BrowserEnvironments.production]: None(),
  [BrowserEnvironments.uat]: None(),
  [BrowserEnvironments["production-sfs"]]: Some(CLIENT_ID_PROD),
  [BrowserEnvironments["preproduction-sfs"]]: Some(CLIENT_ID_PREPROD),
  [BrowserEnvironments["development-sfs"]]: Some(CLIENT_ID_PREPROD),
  [BrowserEnvironments["development-local"]]: None(),
};

const AUTHENTICATION_ISSUER_FOR_ENVIRONMENT: Record<
  BrowserEnvironments,
  string
> = {
  [BrowserEnvironments.production]: "",
  [BrowserEnvironments.uat]: "",
  [BrowserEnvironments["production-sfs"]]: "https://idfed.mpsa.com:443",
  [BrowserEnvironments["preproduction-sfs"]]:
    "https://idfed-preprod.mpsa.com:443",
  [BrowserEnvironments["development-sfs"]]:
    "https://idfed-preprod.mpsa.com:443",
  [BrowserEnvironments["development-local"]]:
    "https://idfed-preprod.mpsa.com:443",
};

/* Execution */

const environmentName = resolveServerEnvironmentName(window.location.hostname);

const authenticationIssuer =
  AUTHENTICATION_ISSUER_FOR_ENVIRONMENT[environmentName];

export const authCodeFlowConfig: Option<AuthConfig> = optionFlatMap(
  CLIENT_ID_FOR_ENVIRONMENT[environmentName],
  (clientId) => {
    /*
     * -- From the file: OAuth_Form_PF_ESA_OIDC_Code_Flow_Public_filled.doc
     * To get the roles claim you must use a dynamic scope named prd:abc
     * (will take all the ABC.* LDAP groups) or prd:abc.example (will take only the specific ABC.EXAMPLE LDAP group)
     * – prd: is a static chain (lowercase) and abc (lowercase) is to be replaced by the real PRD value of the application.
     * The values are coming from the technical target of the applicative services (SA).
     */
    const roleScope = "prd:esa";
    const scope = `openid profile ${roleScope}`;

    if (authenticationIssuer === "") {
      throw new Error(
        `Cannot resolve authentication issuer from environment: ${environmentName}. Check auth.config.ts.`,
      );
    }
    if (localEnv.debugLogs) {
      // eslint-disable-next-line no-console
      console.log(
        `Authentication issuer: ${authenticationIssuer}, client ID: ${clientId}, scope: ${scope}`,
      );
    }

    return Object.freeze({
      issuer: authenticationIssuer,
      redirectUri: localEnv.useHrefRedirectUri
        ? window.location.href // Redirects to the current origin
        : window.location.origin, // Redirects to the current URL (unsupported for now by IDP configuration)
      clientId,
      responseType: "code",
      /*
       * Don't use silentRefresh: it refreshes the page and reruns the authentication flow.
       * When false, uses a refresh_token which is easier
       */
      useSilentRefresh: false,
      // Short timeout factor because the refresh_token seems to expire much more quickly than the access_token's expire time
      timeoutFactor: 0.075,
      scope,
      showDebugInformation: ![
        BrowserEnvironments.production,
        BrowserEnvironments["production-sfs"],
      ].includes(environmentName),
    }) satisfies AuthConfig;
  },
);

export const authExtraConfig = Object.freeze({
  pingFederateLogoutUrl: `${authenticationIssuer}/idp/startSLO.ping`,
  postLogoutRedirectUri: `${window.location.origin}/login`,
});
