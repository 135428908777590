import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgxTranslateCutModule } from "ngx-translate-cut";
import { MaterialModule } from "src/app/material/material.module";
import { FeatureFlagComponent } from "src/app/ui/feature-flag/feature-flag.component";
import { UnbreakableSpacePipe } from "src/app/ui/pipes/unbreakable-space.pipe";

@NgModule({
  declarations: [FeatureFlagComponent, UnbreakableSpacePipe],
  exports: [FeatureFlagComponent, UnbreakableSpacePipe],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    TranslateModule,
    NgxTranslateCutModule,
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UIModule {}
