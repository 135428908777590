import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthErrorEvent, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { distinct, filter } from "rxjs/operators";

import { authExtraConfig } from "src/app/services/auth.config";
import localEnv from "src/environments/localenv";
import { readAttributeFromUnknown } from "src/shared/utils";
import { formatDateToYYYYMMDDHHMMSS } from "src/shared/utils.date";

function accessTokenExpired(event: OAuthEvent): boolean {
  return (
    event.type === "token_expires" &&
    readAttributeFromUnknown(event, "info", "string") === "access_token"
  );
}
@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  // We use distinct here because the authentication status will come as true for every successful silent refresh
  public isAuthenticated$ = this.isAuthenticatedSubject$.pipe(distinct());

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  // However done statuses are better kept individual to account for each authentication challenge
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private navigateToLoginPage() {
    void this.router.navigateByUrl("login");
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
  ) {
    if (localEnv.debugLogs) {
      // Useful for debugging:
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error(
            formatDateToYYYYMMDDHHMMSS(new Date()),
            "OAuthErrorEvent Object:",
            event,
          );
        } else console.warn("OAuthEvent Object:", event);
      });
    }

    /*
     * This is tricky, as it might cause race conditions (where access_token is set in another
     * tab before everything is said and done there.
     * TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
     */
    window.addEventListener("storage", (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== "access_token" && event.key !== null) return;

      console.warn(
        "Noticed changes to access_token (most likely from another tab), updating isAuthenticated",
      );
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken(),
      );

      if (!this.oauthService.hasValidAccessToken()) this.navigateToLoginPage();
    });

    this.oauthService.events.subscribe(() => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken(),
      );
    });

    this.oauthService.events
      .pipe(
        filter(
          /*
           * Not including token_refresh_error here as users may be disconnected from the internet
           * In case the token cannot be refreshed, session_terminated will trigger once the
           * access token expires.
           */
          (event: OAuthEvent) =>
            ["session_terminated", "session_error"].includes(event.type) ||
            accessTokenExpired(event),
        ),
      )
      .subscribe((event: OAuthEvent) => {
        if (localEnv.debugLogs)
          // eslint-disable-next-line no-console
          console.log("Session terminated, redirect to login page:", event);
        this.navigateToLoginPage();
      });

    /*
     * We use only the access_token refresh to check that the user is authenticated
     * The reason is that
     * - the PingFederate refresh_token is not giving us a new id_token
     * - the id_token should ideally be used to check the user is authenticated
     * - but the access_token is also a signed JWT, so we can use it to check the user is authenticated
     */
    this.oauthService.setupAutomaticSilentRefresh({}, "access_token");
  }

  // eslint-disable-next-line complexity
  public async runInitialLoginSequence(): Promise<void> {
    if (localEnv.debugLogs)
      // eslint-disable-next-line no-console
      console.log("runInitialLoginSequence");

    /*
     * 0. LOAD CONFIG:
     * First we have to check to see how the IdServer is
     * currently configured:
     */
    await this.oauthService.loadDiscoveryDocument();

    /*
     * 1. HASH LOGIN:
     * Try to log in via hash fragment after redirect back
     * from IdServer from initImplicitFlow:
     */
    if (!this.oauthService.hasValidAccessToken()) {
      try {
        await this.oauthService.tryLoginCodeFlow({
          disableNonceCheck: false,
          disableOAuth2StateCheck: false,
          preventClearHashAfterLogin: false,
        });
      } catch (loginError) {
        console.error("tryLogin error but do nothing with it", loginError);
      }
    }

    this.isDoneLoadingSubject$.next(true);
  }

  public login() {
    if (localEnv.debugLogs)
      // eslint-disable-next-line no-console
      console.log("Initiating codeflow");
    // Starts the authorization code flow and redirects to user to the auth servers login url.
    this.oauthService.initCodeFlow();
  }

  public async logout() {
    // Setting noRedirectToLogoutUrl to `true`
    await this.oauthService.revokeTokenAndLogout(true);
    let logoutUrl = authExtraConfig.pingFederateLogoutUrl as string;
    let params = new HttpParams();
    params = params.set(
      "TargetResource",
      authExtraConfig.postLogoutRedirectUri,
    );
    logoutUrl =
      logoutUrl + (logoutUrl.indexOf("?") > -1 ? "&" : "?") + params.toString();

    if (localEnv.debugLogs) {
      // eslint-disable-next-line no-console
      console.log(`Triggering logout to ${logoutUrl}`);
      // eslint-disable-next-line no-console
      console.log("Setting up a break point so you can see what's happening");
      debugger;
    }

    window.location.href = logoutUrl;
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  /*
   * These normally won't be exposed from a service like this, but
   * for debugging it makes sense.
   */
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public refresh() {
    return this.oauthService.refreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
