export enum BrowserEnvironments {
  "production" = "production",
  "production-sfs" = "production-sfs",
  "uat" = "uat",
  "preproduction-sfs" = "preproduction-sfs",
  "development-sfs" = "development-sfs",
  "development-local" = "development-local",
}

export function resolveServerEnvironmentName(
  hostname: string,
): BrowserEnvironments {
  if (hostname === "tco-psabank.groupe-psa.com")
    return BrowserEnvironments.production;
  else if (hostname.includes("cloudfront")) return BrowserEnvironments.uat;
  else if (hostname.includes("cdn.esa-b7fa1f5.lzb.stla-aws.net"))
    return BrowserEnvironments["development-sfs"];
  else if (hostname.includes("cdn.esa-773d132.lzb.stla-aws.net"))
    return BrowserEnvironments["preproduction-sfs"];
  else if (hostname.includes("cdn.esa-2df91f4.lzb.stla-aws.net"))
    return BrowserEnvironments["production-sfs"];
  else if (hostname.includes("localhost"))
    return BrowserEnvironments["development-local"];
  else {
    throw new Error(
      `Cannot resolve environment from hostname: ${window.location.hostname}. Check environment.browser.ts.`,
    );
  }
}
