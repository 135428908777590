import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { defer, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GlobalErrorHandlerWithSentry } from "src/app/global-error-handler.service";
import {
  BACKEND_ERROR_HANDLER_RULES,
  USER_INFO_FETCH_ERROR,
} from "src/app/services/user-params.errors";

import { environment } from "src/environments/environment";
import {
  Brand,
  brandGroupFromBrand,
  BrandType,
} from "src/shared/brands.definitions";
import {
  CountriesConfiguration,
  getCountryConfigurationListForBrand,
  LanguagesList,
} from "src/shared/countries.definitions";
import {
  UserInfoOIDC,
  UserInfoParserOIDC,
  UserInfoParserSAML,
  UserInfoSAML,
  UserInfoUrl,
} from "src/shared/user-info";
import {
  exhaustive,
  flattenObj,
  readAttributeFromUnknown,
  throwIfNullish,
  unknownToString,
} from "src/shared/utils";

import { OAuthService } from "angular-oauth2-oidc";
import {
  DEFAULT_USER_PARAMS,
  UserParamsMapper,
} from "src/app/services/user-params.mapper";
import { UserParams } from "src/app/services/user-params.types";
import { SafeParseReturnType } from "zod";

const DEFAULT_DUMMY_USER = {
  uid: "E67875",
  givenname: "",
  sn: "dev",
  preferredlanguage: "FR",
  groups: ["ESA.USER.ALL"],
  rrdi7: "",
  brand: Brand.AC,
  pays: "FR",
  email: "john.dev@stellantis.com",
  telephonenumber: ["+33422521010"],
};

@Injectable({
  providedIn: "root",
})
export class UserParamsService {
  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private gtmService: GoogleTagManagerService,
    private globalErrorHandler: GlobalErrorHandlerWithSentry,
    public oauthService: OAuthService,
  ) {
    this.onParamsChange$ = new ReplaySubject(1);
  }
  languageList = LanguagesList;

  private _refreshLanguageList(): void {
    if (this.params.brandTypes.includes(BrandType.all)) {
      this.languageList = LanguagesList.concat({
        value: "technical",
        valueDisplay: "technical",
      });
    } else this.languageList = LanguagesList;
  }

  countryList = getCountryConfigurationListForBrand(
    CountriesConfiguration,
    DEFAULT_USER_PARAMS.brand,
  );
  params: UserParams = {
    ...DEFAULT_USER_PARAMS,
  };
  onParamsChange$: ReplaySubject<UserParams>;

  private parseOrHandleError<T>(
    safeParse: SafeParseReturnType<T, T>,
    initialInput: unknown,
  ): T | null {
    if (safeParse.success) return safeParse.data;
    else {
      console.error(
        `Error while parsing user info: ${JSON.stringify(
          safeParse.error.format(),
        )}`,
      );
      console.error(`Initial input: ${unknownToString(initialInput)}`);
      this.globalErrorHandler.handleError(new Error(USER_INFO_FETCH_ERROR));
      return null;
    }
  }

  private handleError = (error: unknown): Observable<never> => {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error)
        // A client-side or network error occurred. Not sure how to handle this yet.
        console.error("An error occurred:", error.error.message);
      /*
       * The backend returned an unsuccessful response code.
       * The response body may contain clues as to what went wrongs so logging it.
       */ else {
        console.error(
          `Backend returned code ${error.status}, ` +
            `body was: ${unknownToString(error.error)}`,
        );
        const matchedRule = Object.values(BACKEND_ERROR_HANDLER_RULES)
          .sort((a, b) => b.priority - a.priority)
          .find((rule) => rule.rule(error));

        if (matchedRule) {
          const handler = matchedRule.handler;
          return throwError(() => handler());
        }
      }
    } else console.error(`Unknown error occurred: ${unknownToString(error)}`);

    // Return an observable with a user-facing error message.
    return throwError(() => new Error(USER_INFO_FETCH_ERROR));
  };

  loadUserInfo(
    samlMethod: "saml-link" | "oauthService",
    done?: () => void,
  ): void {
    this.getUserInfo(samlMethod)
      .pipe(
        map(async (userInfoUnparsed: unknown) => {
          // TODO: Deprecate this at the end of #new-authentication
          if (samlMethod === "saml-link") {
            const userInfo = this.parseOrHandleError(
              UserInfoParserSAML.safeParse(userInfoUnparsed),
              userInfoUnparsed,
            );
            if (userInfo !== null) {
              const userParams =
                UserParamsMapper.mapUserInfoSAMLToUserParams(userInfo);

              this.params = { ...userParams };
              this.fireChange();
              await this.pushAsEvent(userInfo, userParams);
            }
          } else if (samlMethod === "oauthService") {
            const userInfo = this.parseOrHandleError(
              UserInfoParserOIDC.safeParse(userInfoUnparsed),
              userInfoUnparsed,
            );
            if (userInfo !== null) {
              const userParams =
                UserParamsMapper.mapUserInfoOIDCToUserParams(userInfo);

              this.params = { ...userParams };
              this.fireChange();
              await this.pushAsEvent(userInfo, userParams);
            }
          }
        }),
        catchError((error) =>
          Promise.resolve(this.globalErrorHandler.handleError(error)),
        ),
      )
      .subscribe(done);
  }

  private async pushAsEvent(
    userInfo: UserInfoSAML | UserInfoOIDC,
    userParams: UserParams,
  ) {
    if (!environment.disableGTM) {
      const userInfoFlat = flattenObj({
        userInfoFromLdap: {
          brand: userInfo.brand,
          preferredlanguage: userInfo.preferredlanguage,
        },
        brand: userParams.brand,
        langugage: userParams.language,
        country: userParams.country,
        rrdi7: userParams.rrdi7,
      });
      await this.gtmService.pushTag({
        event: "user_connected",
        ...userInfoFlat,
      });
    }
  }

  private getUserInfo(
    samlMethod: "saml-link" | "oauthService",
  ): Observable<object> {
    if (environment.useDummyUser) return of(DEFAULT_DUMMY_USER);
    else if (samlMethod === "saml-link") {
      return this.http.get(UserInfoUrl).pipe(
        catchError((error) => this.handleError(error)), // Then handle the error
      );
    } else if (samlMethod === "oauthService") {
      // Errors will be caught thanks to the OAuthService events handled in GlobalErrorHandlerWithSentry
      return defer(() => this.oauthService.loadUserProfile()).pipe(
        map(
          (userProfileContext) =>
            readAttributeFromUnknown(userProfileContext, "info", "object") ?? {
              error: "no user profile context",
            },
        ),
      );
    } else exhaustive(samlMethod);
  }

  fireChange(): void {
    this._refreshLanguageList();
    this._switchLanguage();
    const country = throwIfNullish(
      CountriesConfiguration.find(({ value }) => value === this.params.country),
      {
        customError: `Country ${this.params.country} not found in CountriesConfiguration`,
      },
    );
    this.params = {
      ...this.params,
      languageForLocale: UserParamsMapper.resolveLanguageForLocale(
        this.params.language,
      ),
      currency: country.currency,
      currencyDisplay: country.currencyDisplay,
      standardUnitDefinitions: country.standardUnitDefinitions,
    };
    this.onParamsChange$.next(this.params);
  }

  private _switchLanguage(): void {
    const brandGroup = brandGroupFromBrand(this.params.brand);
    if (this.params.language === "technical")
      this.translate.use(this.params.language);
    else this.translate.use(`${this.params.language}_${brandGroup}`);
  }
}
