import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

interface FeatureFlag {
  enabled: boolean;
  displayName: string;
}

export const featureFlagsNamesAndBetaDefaultValues = [
  {
    name: "pdf",
    devDefaultStatus: true,
    testDefaultStatus: true,
    displayName: "PDF",
  },
  {
    name: "pdfSettings",
    devDefaultStatus: false,
    testDefaultStatus: true,
    displayName: "PDF Settings",
  },
  {
    name: "showCents",
    devDefaultStatus: false,
    testDefaultStatus: true,
    displayName: "Show Cents",
  },
  {
    name: "newVehicleMatrix",
    devDefaultStatus: true,
    testDefaultStatus: false,
    displayName: "New Vehicle Matrix",
  },
  {
    name: "showBetaVehicles",
    devDefaultStatus: true,
    testDefaultStatus: false,
    displayName: "Show Beta Vehicles",
  },
  {
    name: "useExperimentalRvpData",
    devDefaultStatus: false,
    testDefaultStatus: false,
    displayName: "Use experimental RVP data",
  },
] as const;

type FeatureFlagName =
  (typeof featureFlagsNamesAndBetaDefaultValues)[number]["name"];

export type FeatureFlags = Record<FeatureFlagName, FeatureFlag>;

const betaFeatureFlags: FeatureFlags = Object.fromEntries(
  featureFlagsNamesAndBetaDefaultValues.map(
    ({ name, devDefaultStatus, displayName }) => [
      name,
      {
        enabled: devDefaultStatus,
        displayName,
      } satisfies FeatureFlag,
    ],
  ),
) as FeatureFlags;
const prodFeatureFlags: FeatureFlags = Object.fromEntries(
  featureFlagsNamesAndBetaDefaultValues.map(({ name, displayName }) => [
    name,
    {
      enabled: false,
      displayName,
    } satisfies FeatureFlag,
  ]),
) as FeatureFlags;

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  featureFlags$: BehaviorSubject<FeatureFlags>;

  constructor() {
    this.featureFlags$ = new BehaviorSubject<FeatureFlags>(
      environment.enableBetaFeatures ? betaFeatureFlags : prodFeatureFlags,
    );
  }

  toggle(name: string) {
    const featureFlags = this.featureFlags$.getValue();
    if (!featureFlags.hasOwnProperty(name))
      throw new Error(`Feature flag ${name} does not exist`);
    featureFlags[name as FeatureFlagName].enabled =
      !featureFlags[name as FeatureFlagName].enabled;
    this.featureFlags$.next(featureFlags);
  }
}
